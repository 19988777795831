import { useEffect } from 'react';
import { Control, UseFormRegister, useWatch } from 'react-hook-form';

import { Button } from 'ui';
import TextField from 'ui/form/TextField';

export type FormFilters = {
  groupName: string;
  contactName: string;
  cooperativeLegalName: string;
  generationUnitLegalName: string;
  consumerUnitPowerDistributionUnitIdentifier: string;
};

export default function ContactGroupsFormFilters({
  control,
  register,
  isLoading,
  handleHasActiveFilters,
}: {
  isLoading?: boolean;
  control: Control<FormFilters>;
  register: UseFormRegister<FormFilters>;
  handleHasActiveFilters: (hasActiveFilters: number) => void;
}) {
  const activeFiltersCount = useWatch({
    control,
    name: [
      'groupName',
      'contactName',
      'cooperativeLegalName',
      'generationUnitLegalName',
      'consumerUnitPowerDistributionUnitIdentifier',
    ],
  }).filter((filter) => filter).length;

  useEffect(() => {
    handleHasActiveFilters(activeFiltersCount);
  }, [activeFiltersCount, handleHasActiveFilters]);

  return (
    <div className="grid grid-cols-6 gap-4 pb-6 items-end">
      <TextField
        label="Nome do grupo"
        id="groupName"
        placeholder="Digitar"
        {...register('groupName')}
      />

      <TextField
        label="Contato"
        id="contactName"
        placeholder="Digitar"
        {...register('contactName')}
      />

      <TextField
        label="UG"
        placeholder="Digitar"
        id="generationUnitLegalName"
        {...register('generationUnitLegalName')}
      />

      <TextField
        label="UC"
        placeholder="Digitar"
        id="consumerUnitPowerDistributionUnitIdentifier"
        {...register('consumerUnitPowerDistributionUnitIdentifier')}
      />

      <TextField
        label="Cooperativa"
        placeholder="Digitar"
        id="cooperativeLegalName"
        {...register('cooperativeLegalName')}
      />

      <Button type="submit" size="sm" disabled={isLoading} className="self-end w-24">
        Filtrar
      </Button>
    </div>
  );
}
