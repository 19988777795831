import { Cooperative as CooperativeModel } from 'cooperatives/models/cooperative';

export const Cooperative = (id = new Date().valueOf()): CooperativeModel => ({
  id,
  cooperativeSubscriptionCostCents: (id * 100) / 2,
  cooperativeLegalName: `Cooperative Name ${id}`,
  cooperativeDocumentId: `Cooperative Document ${id}`,
  cooperativeAddressPostalCode: id,
  cooperativeDeletedAt: null,
  cooperativeAddressState: 'PR',
  cooperativeAddressCity: `City ${id}`,
  cooperativeAddressStreet: `Street ${id}`,
  cooperativeAddressDistrict: `District ${id}`,
  cooperativeAddressComplement: `Complement ${id}`,
  cooperativeHeadquarter: null,
  financialAccountId: id,
  financialAccount: {
    id,
    financialAccountLegalName: `Financial account legal name ${id}`,
  },
});

export const CooperativeSubsidiary = (
  id = new Date().valueOf()
): CooperativeModel => ({
  ...Cooperative(id),
  cooperativeHeadquarter: Cooperative(id + 1),
});

export default Cooperative;
