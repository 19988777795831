import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import useToastContext from 'ui/hooks/useToast';
import { updateCacheById } from 'graphql/apollo/cache';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import {
  CONSUMER_UNITS_QUERY,
  ConsumerUnitsList,
} from 'consumerUnits/graphql/consumerUnitsQuery';
import {
  CONSUMER_UNIT_DELETE_MUTATION,
  ConsumerUnitDeleteMutationVariables,
  consumerUnitsTypename,
} from 'consumerUnits/graphql/consumerUnitDeleteMutation';
import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';
import ConsumerUnitsTable from 'consumerUnits/components/table/ConsumerUnitsTable';
import AddButton from 'dashboard/components/dashboard/AddButton';
import ConsumerUnitFormFilters, {
  FormFields,
} from 'consumerUnits/components/form/ConsumerUnitFormFilters';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  PowerDistributionUnitsSelectList,
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES,
} from 'powerDistributionUnits/graphql/powerDistributionUnitsSelectQuery';
import {
  GenerationUnitsSelectList,
  GENERATION_UNITS_SELECT_QUERY,
  GENERATION_UNITS_SELECT_QUERY_VARIABLES,
} from 'generationUnits/graphql/generationUnitsSelectQuery';

type ListState = {
  consumerUnits: ConsumerUnit[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const DASHBOARD_CONSUMER_UNITS_CREATE_ROUTE =
  '/dashboard/business-entities/consumer-units/create';

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de Consumidores',
};

const DELETE_SUCCESS_TOAST: ToastProps = {
  text: 'Consumidor deletado com sucesso',
  title: 'Sucesso',
  variant: 'primary',
};

const DETETE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar deletar o Consumidor',
  title: 'Algo deu errado!',
  variant: 'danger',
};

export default function ConsumerUnits() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>();

  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const { ConfirmOverlay, closeConfirm, showConfirm } = useConfirm();
  const [currentConsumerUnitId, setCurrentConsumerUnitId] = useState<number>(0);
  const [listState, setListState] = useState<ListState>({
    consumerUnits: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [consumerUnitsList, { data, loading, error, refetch }] =
    useLazyQuery<ConsumerUnitsList>(
      CONSUMER_UNITS_QUERY,
      INITIAL_QUERY_STATE_CONFIG
    );

  const [consumerUnitDeleteMutation, { loading: consumerUnitDeleteLoading }] =
    useMutation<ConsumerUnitDeleteMutationVariables>(CONSUMER_UNIT_DELETE_MUTATION, {
      onError() {
        addToast(DETETE_ERROR_TOAST);
      },
      onCompleted() {
        addToast(DELETE_SUCCESS_TOAST);
      },
    });

  useEffect(() => {
    consumerUnitsList();
  }, [consumerUnitsList]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const [
    powerDistributionUnitsSelect,
    {
      data: powerDistributionUnits,
      loading: loadingPowerDistributionUnits,
      refetch: refetchPowerDistributionUnits,
    },
  ] = useLazyQuery<PowerDistributionUnitsSelectList>(
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES
  );

  useEffect(() => {
    refetchPowerDistributionUnits && refetchPowerDistributionUnits();

    powerDistributionUnitsSelect();
  }, [powerDistributionUnitsSelect, refetchPowerDistributionUnits]);

  const [
    generationUnitsSelect,
    {
      data: generationUnits,
      loading: loadingGenerationUnits,
      refetch: refetchGenerationUnits,
    },
  ] = useLazyQuery<GenerationUnitsSelectList>(
    GENERATION_UNITS_SELECT_QUERY,
    GENERATION_UNITS_SELECT_QUERY_VARIABLES
  );

  useEffect(() => {
    refetchGenerationUnits && refetchGenerationUnits();

    generationUnitsSelect();
  }, [generationUnitsSelect, refetchGenerationUnits]);

  const isLoading =
    loading ||
    consumerUnitDeleteLoading ||
    loadingPowerDistributionUnits ||
    loadingGenerationUnits;

  const onSubmit: SubmitHandler<FormFields> = (consumerUnitCreateInput: {
    [key: string]: any;
  }) => {
    Object.keys(consumerUnitCreateInput).forEach((key) => {
      if (!!!consumerUnitCreateInput[key]) {
        delete consumerUnitCreateInput[key];
      }
    });

    refetch({
      filters: consumerUnitCreateInput,
    });
  };

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    if (data) {
      setListState({
        consumerUnits: data.consumerUnits.entries,
        hasNextPage: !!data.consumerUnits.afterCursor,
        hasPreviousPage: !!data.consumerUnits.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  const handleClickNext = () =>
    refetch &&
    refetch({
      after: data?.consumerUnits.afterCursor,
      before: null,
    });

  const handleClickBefore = () =>
    refetch &&
    refetch({
      after: null,
      before: data?.consumerUnits.beforeCursor,
    });

  const onClickEditButton = (id: number) =>
    push(`/dashboard/business-entities/consumer-units/${id}/edit`);

  const onClickRemoveButton = (id: number) => {
    setCurrentConsumerUnitId(id);
    showConfirm();
  };

  const onConfirmDelete = () => {
    closeConfirm();

    consumerUnitDeleteMutation({
      variables: { id: currentConsumerUnitId },
      update(cache) {
        updateCacheById(cache, currentConsumerUnitId, consumerUnitsTypename);
      },
    });
  };

  const onCancelDelete = () => closeConfirm();

  const onClickAddButton = () => push(DASHBOARD_CONSUMER_UNITS_CREATE_ROUTE);

  return (
    <Dashboard
      dashboardMainHeaderTitle={
        <DashboardMainHeaderForm title="Unidades Consumidoras">
          <AddButton onClick={onClickAddButton} label="Unidade Consumidora" />
        </DashboardMainHeaderForm>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ConsumerUnitFormFilters
          powerDistributionUnits={
            powerDistributionUnits?.powerDistributionUnits.entries
          }
          generationUnits={generationUnits?.generationUnits.entries}
          control={control}
          errors={errors}
          register={register}
          isLoading={isLoading}
        />
      </form>

      <div className="rounded-lg bg-gray-background">
        {listState && (
          <>
            <ConsumerUnitsTable
              consumerUnits={listState.consumerUnits}
              onClickEditButton={onClickEditButton}
              onClickRemoveButton={onClickRemoveButton}
              disableEditButton={isLoading}
              disableRemoveButton={isLoading}
            />
            <Pagination
              onNextClick={handleClickNext}
              onPreviousClick={handleClickBefore}
              disableNext={!listState.hasNextPage || isLoading}
              disableBefore={!listState.hasPreviousPage || isLoading}
            />
          </>
        )}
        <ConfirmOverlay
          title="Desativação da Unidade Consumidora"
          text="Tem certeza que deseja desativar essa Unidade Consumidora? Todos os dados serão permanentemente removidos do nosso sistema. Essa ação não poderá ser revertida."
          variant="danger"
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
        />
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
