export type MaskProps = {
  date: (value: string) => string;
  phone: (value: string) => string;
  email: (value: string) => string;
  decimal: (value: string) => string;
  document: (value: string) => string;
  currency: (value: string) => string;
  textOnly: (value: string) => string;
  numberOnly: (value: string) => string;
  postalCode: (value: string) => string;
  mobilePhone: (value: string) => string;
  dateMonthYear: (value: string) => string;
  companyDocument: (value: string) => string;
  dateDayMonthYear: (value: string) => string;
  individualDocument: (value: string) => string;
  decimalSixDigitsPrecision: (value: string) => string;
  decimalEightDigitsPrecision: (value: string) => string;
};

export type MaskInterface =
  | 'date'
  | 'phone'
  | 'email'
  | 'decimal'
  | 'document'
  | 'currency'
  | 'textOnly'
  | 'postalCode'
  | 'numberOnly'
  | 'mobilePhone'
  | 'companyDocument'
  | 'individualDocument'
  | 'decimalSixDigitsPrecision'
  | 'decimalEightDigitsPrecision';

const masks: MaskProps = {
  phone: (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d\d)(\d)/g, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  },
  mobilePhone: (value: string) => {
    if (value.length > 15) {
      return value.slice(0, -1);
    }

    return value
      .replace(/\D/g, '')
      .replace(/^(\d\d)(\d)/g, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2');
  },
  individualDocument: (value: string) => {
    if (value.length > 14) {
      return value.slice(0, -1);
    }

    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  },
  companyDocument: (value: string) => {
    if (value.length > 18) {
      return value.slice(0, -1);
    }

    return value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  },
  document: (value: string) => {
    if (value.replace(/\D/g, '').length <= 11) {
      return masks['individualDocument'](value);
    } else {
      return masks['companyDocument'](value);
    }
  },
  postalCode: (value: string) => {
    if (value.length > 9) {
      return value.slice(0, -1);
    }

    return value.replace(/\D/g, '').replace(/^(\d{5})(\d)/, '$1-$2');
  },
  textOnly: (value: string) => {
    return value.replace(/\d/g, '');
  },
  numberOnly: (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{21})$/, '$1.$2')
      .replace(/(\d)(\d{18})$/, '$1.$2')
      .replace(/(\d)(\d{15})$/, '$1.$2')
      .replace(/(\d)(\d{12})$/, '$1.$2')
      .replace(/(\d)(\d{9})$/, '$1.$2')
      .replace(/(\d)(\d{6})$/, '$1.$2')
      .replace(/(\d)(\d{3})$/, '$1.$2');
  },
  decimal: (value: string) => {
    const currentValue = value.replace(/\D/g, '');

    if (
      (currentValue.split(',')[0] === '00' && currentValue.length >= 4) ||
      (currentValue.length >= 5 &&
        currentValue.split(',').filter((char) => char === '0'))
    ) {
      return currentValue
        .replace(/^0+/, '')
        .replace(/\D/g, '')
        .replace(/(\d)(\d{20})$/, '$1.$2')
        .replace(/(\d)(\d{17})$/, '$1.$2')
        .replace(/(\d)(\d{14})$/, '$1.$2')
        .replace(/(\d)(\d{11})$/, '$1.$2')
        .replace(/(\d)(\d{8})$/, '$1.$2')
        .replace(/(\d)(\d{5})$/, '$1.$2')
        .replace(/(\d)(\d{2})$/, '$1,$2');
    }

    return currentValue.replace(/\D/g, '').replace(/(\d)(\d{2})$/, '$1,$2');
  },
  currency: (value: string) => {
    if (value.length) {
      return `R$ ${masks['decimal'](value)}`;
    }

    return value;
  },
  email: (value: string) => {
    const domain = value.split('@')[1];

    if (value.indexOf('@') !== -1 && domain.length <= 0) {
      return value.replace(/@/g, '@nexenergy.com.br');
    } else if (value.indexOf('@') !== -1 && domain.length === 1) {
      return value.replace(/@n/g, '');
    }

    return value;
  },
  dateMonthYear: (value: string) => {
    if (value.length > 7) {
      return value.slice(0, -1);
    }

    return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1/$2');
  },
  dateDayMonthYear: (value: string) => {
    if (value.length > 10) {
      return value.slice(0, -1);
    }

    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2');
  },
  date: (value: string) => {
    if (value.length > 7 && value.length <= 10) {
      return masks['dateDayMonthYear'](value);
    } else {
      return masks['dateMonthYear'](value);
    }
  },
  decimalSixDigitsPrecision: (value: string) => {
    const regex = /^(\d{0,3})(,\d{0,6})?$/;
    const splitedValue = value.split(',')[0];

    if (value && !regex.test(value)) {
      return value.slice(0, -1);
    }

    if (splitedValue === '00' || splitedValue === '000') {
      return value.slice(1);
    }

    if (splitedValue.length > 1 && splitedValue.match(/^0+/)) {
      return value.replace(/^0+/, '');
    }

    return value;
  },
  decimalEightDigitsPrecision: (value: string) => {
    const regex = /^(\d{0,3})(,\d{0,8})?$/;
    const splitedValue = value.split(',')[0];

    if (value && !regex.test(value)) {
      return value.slice(0, -1);
    }

    if (splitedValue === '00' || splitedValue === '000') {
      return value.slice(1);
    }

    if (splitedValue.length > 1 && splitedValue.match(/^0+/)) {
      return value.replace(/^0+/, '');
    }

    return value;
  },
};

export default masks;
