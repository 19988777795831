import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import {
  TARIFF_FLAG_MODALITIES_QUERY,
  TariffFlagModalitiesList,
} from 'tariffFlagTariffs/graphql/tariffFlagModalitiesQuery';

import { ToastProps } from 'ui/contexts/overlay/Toast';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import TariffFlagsTable from 'tariffFlagTariffs/components/table/TariffFlagModalitiesTable';
import { useModal } from 'ui/contexts/overlay/Modal';
import {
  CreateTariffFlagModalityModal,
  CreateTariffFlagTariffModal,
} from 'tariffFlagTariffs/components/modal';
import AddButton from 'dashboard/components/dashboard/AddButton';
import { TariffFlagModality } from 'tariffFlagTariffs/models/tariffFlagModality';

type ListState = {
  tariffFlagModalities: TariffFlagModality[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de Bandeiras',
};

export default function TariffModalitiesPage() {
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const {
    ModalOverlay,
    closeConfirm: modalCloseConfirm,
    showConfirm: modalShowConfirm,
  } = useModal();

  const [showTariffFlagTariffModal, setShowTariffFlagTariffModal] = useState(false);
  const [showTariffFlagModalityModal, setShowTariffFlagModalityModal] =
    useState(false);

  const [listState, setListState] = useState<ListState>({
    tariffFlagModalities: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [tariffFlagModalitiesList, { data, loading, error, refetch }] =
    useLazyQuery<TariffFlagModalitiesList>(
      TARIFF_FLAG_MODALITIES_QUERY,
      INITIAL_QUERY_STATE_CONFIG
    );

  useEffect(() => {
    tariffFlagModalitiesList();
  }, [tariffFlagModalitiesList]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading = loading;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    if (data) {
      setListState({
        tariffFlagModalities: data.tariffFlagModalities.entries,
        hasNextPage: !!data.tariffFlagModalities.afterCursor,
        hasPreviousPage: !!data.tariffFlagModalities.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  const handleClickNext = () => {
    refetch &&
      refetch({
        after: data?.tariffFlagModalities.afterCursor,
        before: null,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data?.tariffFlagModalities.beforeCursor,
      });
  };

  const onClickAddModalityButton = () => {
    setShowTariffFlagTariffModal(false);
    setShowTariffFlagModalityModal(true);

    modalShowConfirm();
  };
  const onClickAddTariffButton = () => {
    setShowTariffFlagModalityModal(false);
    setShowTariffFlagTariffModal(true);

    modalShowConfirm();
  };
  const onClickCloseModal = () => modalCloseConfirm();

  return (
    <Dashboard
      dashboardHeader={<></>}
      dashboardMainHeaderTitle={
        <DashboardMainHeaderForm title="Bandeiras">
          <>
            <AddButton onClick={onClickAddTariffButton} label="Nova Tarifa" />
            <AddButton onClick={onClickAddModalityButton} label="Nova Bandeira" />
          </>
        </DashboardMainHeaderForm>
      }
    >
      <div className="rounded-lg bg-gray-background">
        {listState && (
          <>
            <TariffFlagsTable
              tariffFlagModalities={listState.tariffFlagModalities}
            />
            <Pagination
              onNextClick={handleClickNext}
              onPreviousClick={handleClickBefore}
              disableNext={!listState.hasNextPage || isLoading}
              disableBefore={!listState.hasPreviousPage || isLoading}
            />
          </>
        )}

        {showTariffFlagModalityModal && (
          <CreateTariffFlagModalityModal
            ModalOverlay={ModalOverlay}
            onClickCloseModal={onClickCloseModal}
          />
        )}

        {showTariffFlagTariffModal && (
          <CreateTariffFlagTariffModal
            ModalOverlay={ModalOverlay}
            onClickCloseModal={onClickCloseModal}
          />
        )}
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
