import {
  Groups as GroupsModel,
  Contact as ContactModel,
  Entities as EntitiesModel,
  ContactGroups as ContactGroupsModel,
} from 'contactGroups/models/contactGroups';

import Cooperative from './cooperative';
import ConsumerUnit from './consumerUnit';
import GenerationUnit from './generationUnit';

export const Contact = (id = new Date().valueOf()): ContactModel => ({
  id,
  contactName: `Joe Doe ${id}`,
  contactEmail: `joe${id}@doe.com`,
  contactPhone: `(${id}) 123456789`,
});

export const Groups = (id = new Date().valueOf()): GroupsModel => ({
  id,
  groupName: `Grupo de Contatos ${id}`,
});

export const Entities = (id = new Date().valueOf()): EntitiesModel => ({
  cooperative: [Cooperative(id)],
  consumerUnit: [ConsumerUnit(id)],
  generationUnit: [GenerationUnit(id)],
});

export const ContactGroups = (id = new Date().valueOf()): ContactGroupsModel => ({
  id,
  groups: Groups(id),
  entities: Entities(id),
  contacts: {
    domain: [Contact(id)],
    owner: [Contact(id + 1)],
    financial: [Contact(id + 2)],
    juridical: [Contact(id + 3)],
    technical: [Contact(id + 4)],
    administrative: [Contact(id + 5)],
  },
});

export default ContactGroups;
