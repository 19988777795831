import { gql } from '@apollo/client';

import { ContactGroups } from 'contactGroups/models/contactGroups';

interface ContactGroupConnection {
  entries: ContactGroups[];
  afterCursor: string | null;
  beforeCursor: string | null;
}

export interface ContactGroupList {
  contactGroups: ContactGroupConnection;
}

export const contactGroupsTypename = 'ContactGroupConnection';

const MAX_PAGE_SIZE = 100;

export const CONTACT_GROUPS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const CONTACT_GROUPS_QUERY = gql`
  query contactGroups(
    $pageSize: Int
    $after: String
    $before: String
    $filters: ContactGroupFilterOptions
  ) {
    contactGroups(
      after: $after
      before: $before
      filters: $filters
      pageSize: $pageSize
    ) {
      afterCursor
      beforeCursor
      entries {
        id
        groups {
          id
          groupName
        }
        contacts {
          id
          contactName
          contactEmail
          contactPhone
          contactType {
            id
            contactTypeName
          }
        }
        entities {
          cooperative {
            id
          }
        }
      }
    }
  }
`;
