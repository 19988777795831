import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import GenerationUnitsTable from 'generationUnits/components/table/GenerationUnitsTable';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import {
  GENERATION_UNITS_QUERY,
  GenerationUnitsList,
} from 'generationUnits/graphql/generationUnitsQuery';
import {
  GENERATION_UNIT_DELETE_MUTATION,
  GenerationUnitDeleteMutationVariables,
} from 'generationUnits/graphql/generationUnitDeleteMutation';
import { GenerationUnit } from 'generationUnits/models/generationUnit';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { useHistory } from 'react-router-dom';
import { updateCacheById } from 'graphql/apollo/cache';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import { GenerationUnitsTypename } from 'generationUnits/graphql/generationUnitsQuery';
import AddButton from 'dashboard/components/dashboard/AddButton';

import GenerationUnitFormFilters, {
  FormFields,
} from 'generationUnits/components/form/GenerationUnitFormFilters';
import {
  PowerDistributionUnitsSelectList,
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES,
} from 'powerDistributionUnits/graphql/powerDistributionUnitsSelectQuery';
import { SubmitHandler, useForm } from 'react-hook-form';

type ListState = {
  generationUnits: GenerationUnit[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const DASHBOARD_GENERATION_UNITS_CREATE_ROUTE =
  '/dashboard/business-entities/generation-units/create';

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de Unidades Geradoras',
};

const DELETE_SUCCESS_TOAST: ToastProps = {
  text: 'Unidade Geradora deletada com sucesso',
  title: 'Sucesso',
  variant: 'primary',
};

const DETETE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar deletar a Unidade Geradora',
  title: 'Algo deu errado!',
  variant: 'danger',
};

export default function GenerationUnitsPage() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>();

  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const { ConfirmOverlay, closeConfirm, showConfirm } = useConfirm();
  const [currentGenerationUnitId, setCurrentGenerationUnitId] = useState<number>(0);
  const [listState, setListState] = useState<ListState>({
    generationUnits: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [generationUnitList, { data, loading, error, refetch }] =
    useLazyQuery<GenerationUnitsList>(
      GENERATION_UNITS_QUERY,
      INITIAL_QUERY_STATE_CONFIG
    );

  const [generationUnitDeleteMutation, { loading: generationUnitDeleteLoading }] =
    useMutation<GenerationUnitDeleteMutationVariables>(
      GENERATION_UNIT_DELETE_MUTATION,
      {
        onError() {
          addToast(DETETE_ERROR_TOAST);
        },
        onCompleted() {
          addToast(DELETE_SUCCESS_TOAST);
        },
      }
    );

  useEffect(() => {
    generationUnitList();
  }, [generationUnitList]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const [
    powerDistributionUnitsSelect,
    {
      data: powerDistributionUnits,
      loading: loadingPowerDistributionUnits,
      refetch: refetchPowerDistributionUnits,
    },
  ] = useLazyQuery<PowerDistributionUnitsSelectList>(
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES
  );

  useEffect(() => {
    refetchPowerDistributionUnits && refetchPowerDistributionUnits();

    powerDistributionUnitsSelect();
  }, [powerDistributionUnitsSelect, refetchPowerDistributionUnits]);

  const isLoading =
    loading || generationUnitDeleteLoading || loadingPowerDistributionUnits;

  const onSubmit: SubmitHandler<FormFields> = (generationUnitCreateInput: {
    [key: string]: any;
  }) => {
    Object.keys(generationUnitCreateInput).forEach((key) => {
      if (!!!generationUnitCreateInput[key]) {
        delete generationUnitCreateInput[key];
      }
    });

    refetch({
      filters: generationUnitCreateInput,
    });
  };

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    if (data) {
      setListState({
        generationUnits: data.generationUnits.entries,
        hasNextPage: !!data.generationUnits.afterCursor,
        hasPreviousPage: !!data.generationUnits.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  const handleClickNext = () => {
    refetch &&
      refetch({
        after: data?.generationUnits.afterCursor,
        before: null,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data?.generationUnits.beforeCursor,
      });
  };

  const onClickEditButton = (id: number) =>
    push(`/dashboard/business-entities/generation-units/${id}/edit`);

  const onClickRemoveButton = (id: number) => {
    setCurrentGenerationUnitId(id);
    showConfirm();
  };

  const onConfirmDelete = () => {
    closeConfirm();

    generationUnitDeleteMutation({
      variables: { id: currentGenerationUnitId },
      update(cache) {
        updateCacheById(cache, currentGenerationUnitId, GenerationUnitsTypename);
      },
    });
  };

  const onCancelDelete = () => closeConfirm();

  const onClickAddButton = () => push(DASHBOARD_GENERATION_UNITS_CREATE_ROUTE);

  return (
    <Dashboard
      dashboardMainHeaderTitle={
        <DashboardMainHeaderForm title="Unidades Geradoras">
          <AddButton onClick={onClickAddButton} label="Unidade Geradora" />
        </DashboardMainHeaderForm>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <GenerationUnitFormFilters
          powerDistributionUnits={
            powerDistributionUnits?.powerDistributionUnits.entries
          }
          control={control}
          errors={errors}
          register={register}
          isLoading={isLoading}
        />
      </form>

      <div className="rounded-lg bg-gray-background">
        {listState && (
          <>
            <GenerationUnitsTable
              generationUnits={listState.generationUnits}
              onClickEditButton={onClickEditButton}
              onClickRemoveButton={onClickRemoveButton}
              disableEditButton={isLoading}
              disableRemoveButton={isLoading}
            />
            <Pagination
              onNextClick={handleClickNext}
              onPreviousClick={handleClickBefore}
              disableNext={!listState.hasNextPage || isLoading}
              disableBefore={!listState.hasPreviousPage || isLoading}
            />
          </>
        )}
        <ConfirmOverlay
          title="Desativação da Unidade Geradora!"
          text="Tem certeza que deseja desativar essa Unidade Geradora? Todos os dados serão permanentemente removidos do nosso sistema. Essa ação não poderá ser revertida."
          variant="danger"
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
        />
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
