import { SyntheticEvent } from 'react';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';
import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';
import { Link } from 'react-router-dom';

type Props = {
  consumerUnit: ConsumerUnit;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};
const DASHBOARD_APPORTIONMENTS_CYCLE_ROUTE =
  '/dashboard/billing-cycle/apportionment-cycle/credit-distribution';

export default function ConsumersTableItem({
  consumerUnit: {
    id,
    consumerUnitAddressState,
    consumerUnitPowerDistributionUnitIdentifier,
    consumerUnitConsumptionGroupType,
    powerDistributionUnit: { powerDistributionUnitLegalName },
    cooperativeMember: { cooperativeMemberLegalName },
    cooperative: { cooperativeLegalName },
    apportionmentCycleConsumerUnit,
  },
  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props) {
  const handleClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  const formattedPowerDistributionUnitLegalName =
    powerDistributionUnitLegalName.slice(0, 15);

  return (
    <TableBodyRow dataTestId={`consumer-unit-row-id-${id}`}>
      <TableBodyColumn>
        {consumerUnitPowerDistributionUnitIdentifier}
      </TableBodyColumn>
      <TableBodyColumn>{cooperativeMemberLegalName}</TableBodyColumn>
      <TableBodyColumn>{cooperativeLegalName}</TableBodyColumn>
      <TableBodyColumn className="text-center">
        {apportionmentCycleConsumerUnit?.generationUnit?.generationUnitLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="font-bold">
        {formattedPowerDistributionUnitLegalName}...
      </TableBodyColumn>
      <TableBodyColumn>{consumerUnitAddressState}</TableBodyColumn>
      <TableBodyColumn className="text-center font-bold">
        {consumerUnitConsumptionGroupType}
      </TableBodyColumn>
      <TableBodyColumn className="text-center underline">
        <Link to={DASHBOARD_APPORTIONMENTS_CYCLE_ROUTE}>
          {apportionmentCycleConsumerUnit?.apportionmentCycleConsumerUnitPercentual}
        </Link>
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} disabled={disableEditButton} />
        <RemoveButton
          onClick={handleClickRemoveButton}
          disabled={disableRemoveButton}
        />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
