import { gql } from '@apollo/client';
import { TariffFlagModality } from 'tariffFlagTariffs/models/tariffFlagModality';

interface TariffFlagModalityConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: TariffFlagModality[];
}

export interface TariffFlagModalitiesList {
  tariffFlagModalities: TariffFlagModalityConnection;
}

export const tariffFlagModalitiesTypename = 'TariffFlagModalityConnection';

export const TARIFF_FLAG_MODALITIES_QUERY = gql`
  query tariffFlagModalities($after: String, $before: String, $pageSize: Int) {
    tariffFlagModalities(after: $after, before: $before, pageSize: $pageSize) {
      entries {
        id
        tariffFlagModalityModalityFlag
        tariffFlagModalityValidityDate
        tariffFlagTariff {
          tariffFlagTariffFee
          tariffFlagTariffModalityFlag
          tariffFlagTariffValidityPeriod
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
