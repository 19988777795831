import { gql } from '@apollo/client';

import { Bank } from 'banks/models/bank';

interface BankConnection {
  entries: Bank[];
  afterCursor: string | null;
  beforeCursor: string | null;
}

export interface BankList {
  banks: BankConnection;
}

export const banksTypename = 'BankConnection';

const MAX_PAGE_SIZE = 100;

export const BANKS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const BANKS_QUERY = gql`
  query banks($after: String, $before: String, $pageSize: Int) {
    banks(after: $after, before: $before, pageSize: $pageSize) {
      afterCursor
      beforeCursor
      entries {
        id
        bankName
        bankCode
      }
    }
  }
`;
