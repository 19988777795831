import {
  Control,
  Controller,
  FieldError,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import TextField from 'ui/form/TextField';
import SelectField from 'ui/form/SelectField';
import { useEffect, useState } from 'react';
import { Option } from 'ui/components/form/SelectInput';
import { PowerDistributionUnitSelect } from 'powerDistributionUnits/models/powerDistributionUnit';
import NumberOnlyField from 'ui/form/NumberOnlyField';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';
import RadioField from 'ui/form/RadioField';
import omit from 'lodash/omit';
import { CooperativeMemberSelect } from 'cooperatives/models/cooperativeMember';

type Field =
  | 'consumerUnitPowerDistributionUnitIdentifier'
  | 'consumerUnitAddressState'
  | 'consumerUnitAddressCity'
  | 'consumerUnitAddressPostalCode'
  | 'consumerUnitAddressStreet'
  | 'consumerUnitAddressDistrict'
  | 'consumerUnitAddressComplement'
  | 'consumerUnitConsumptionGroupType'
  | 'powerDistributionUnitCredentialsUser'
  | 'powerDistributionUnitCredentialsPasswordInput'
  | 'consumerUnitModality'
  | 'consumerUnitConsumptionClass'
  | 'cooperativeId'
  | 'cooperativeMemberId'
  | 'powerDistributionUnitId';

export const FORM_FIELDS: Field[] = [
  'consumerUnitPowerDistributionUnitIdentifier',
  'consumerUnitAddressState',
  'consumerUnitAddressCity',
  'consumerUnitAddressPostalCode',
  'consumerUnitAddressStreet',
  'consumerUnitAddressDistrict',
  'consumerUnitAddressComplement',
  'consumerUnitConsumptionGroupType',
  'powerDistributionUnitCredentialsUser',
  'powerDistributionUnitCredentialsPasswordInput',
  'consumerUnitModality',
  'consumerUnitConsumptionClass',
  'cooperativeId',
  'cooperativeMemberId',
  'powerDistributionUnitId',
];

export type FormFields = {
  consumerUnitPowerDistributionUnitIdentifier: string;
  consumerUnitAddressState: string;
  consumerUnitAddressCity: string;
  consumerUnitAddressPostalCode: number;
  consumerUnitAddressStreet: string;
  consumerUnitAddressDistrict: string;
  consumerUnitAddressComplement?: string;
  consumerUnitConsumptionGroupType: string;
  powerDistributionUnitCredentialsUser: string;
  powerDistributionUnitCredentialsPasswordInput: string;
  consumerUnitModality: string;
  consumerUnitConsumptionClass: string;
  cooperativeId: number;
  cooperativeMemberId: number;
  powerDistributionUnitId: number;
};

export type FormErrors = {
  consumerUnitPowerDistributionUnitIdentifier?: FieldError | undefined;
  consumerUnitAddressState?: FieldError | undefined;
  consumerUnitAddressCity?: FieldError | undefined;
  consumerUnitAddressPostalCode?: FieldError | undefined;
  consumerUnitAddressStreet?: FieldError | undefined;
  consumerUnitAddressDistrict?: FieldError | undefined;
  consumerUnitAddressComplement?: FieldError | undefined;
  consumerUnitConsumptionGroupType?: FieldError | undefined;
  powerDistributionUnitCredentialsUser?: FieldError | undefined;
  powerDistributionUnitCredentialsPasswordInput?: FieldError | undefined;
  consumerUnitModality?: FieldError | undefined;
  consumerUnitConsumptionClass?: FieldError | undefined;
  cooperativeMemberId?: FieldError | undefined;
  powerDistributionUnitId?: FieldError | undefined;
};

const consumerUnitConsumptionClassOptions = [
  {
    key: 'COMMERCIAL',
    value: 'COMERCIAL',
  },
  {
    key: 'RESIDENTIAL',
    value: 'RESIDENCIAL',
  },
  {
    key: 'INDUSTRIAL',
    value: 'INDUSTRIAL',
  },
];

export default function ConsumerUnitFormFields({
  errors,
  register,
  powerDistributionUnits,
  cooperativeMembers,
  control,
  disableFields,
  resetField,
  setValue,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  powerDistributionUnits?: PowerDistributionUnitSelect[];
  cooperativeMembers?: CooperativeMemberSelect[];
  disableFields?: boolean;
  control: Control<FormFields>;
  resetField: UseFormResetField<FormFields>;
  setValue: UseFormSetValue<FormFields>;
}) {
  const [cooperativeMemberId, cooperativeId] = useWatch({
    control,
    name: ['cooperativeMemberId', 'cooperativeId'],
  });

  const [powerDistributionUnitOptions, setPowerDistributionUnitOptions] = useState<
    Option<number>[]
  >([]);

  useEffect(() => {
    !!powerDistributionUnits &&
      setPowerDistributionUnitOptions(
        powerDistributionUnits?.map((powerDistributionUnit) => ({
          key: powerDistributionUnit?.id,
          value: powerDistributionUnit?.powerDistributionUnitLegalName,
        })) || []
      );
  }, [powerDistributionUnits]);

  const [cooperativeMemberOptions, setCooperativeMemberOptions] = useState<
    Option<number>[]
  >([]);
  useEffect(() => {
    !!cooperativeMembers &&
      setCooperativeMemberOptions(
        cooperativeMembers?.map((cooperativeMember) => ({
          key: cooperativeMember?.id,
          value: `${cooperativeMember?.cooperativeMemberLegalName} - ${cooperativeMember?.cooperativeMemberDocumentId}`,
        })) || []
      );
  }, [cooperativeMembers]);

  const [consumerUnitModalities] = useState<Option<string>[]>([
    { key: 'GREEN', value: 'VERDE' },
    { key: 'BLUE', value: 'AZUL' },
    { key: 'CONVENTIONAL', value: 'CONVENCIONAL' },
  ]);

  const [consumerUnitModalityOptions, setConsumerUnitModalityOptions] = useState<
    Option<string>[]
  >([]);
  useEffect(
    () =>
      setConsumerUnitModalityOptions(
        consumerUnitModalities?.map((consumerUnitModality) => ({
          key: `${consumerUnitModality.key}`,
          value: consumerUnitModality.value,
        })) || []
      ),
    [consumerUnitModalities]
  );

  useEffect(() => {
    if (!!cooperativeMembers) {
      const cooperativeMember = cooperativeMembers?.find(
        (item) => item?.id === cooperativeMemberId
      );
      const memberCooperative = cooperativeMember
        ? {
            id: cooperativeMember?.cooperative?.id,
            value: cooperativeMember?.cooperative?.cooperativeLegalName,
          }
        : undefined;

      setCooperativeOptions(
        (memberCooperative && [
          { key: memberCooperative?.id, value: memberCooperative?.value },
        ]) ||
          []
      );

      if (memberCooperative) {
        setValue('cooperativeId', memberCooperative?.id);
      } else {
        resetField('cooperativeId');
      }
    }
  }, [cooperativeMembers, cooperativeMemberId, resetField, setValue]);

  const [cooperativeOptions, setCooperativeOptions] = useState<Option<number>[]>([]);

  return (
    <>
      <div className="grid grid-cols-3 gap-8">
        <TextField
          required
          error={errors?.consumerUnitPowerDistributionUnitIdentifier?.message}
          label="Número da UC"
          id={'consumerUnitPowerDistributionUnitIdentifier'}
          {...register('consumerUnitPowerDistributionUnitIdentifier', {
            required: 'Campo obrigatório',
            disabled: disableFields,
          })}
          placeholder="Ex: 43213422"
          disabled={disableFields}
        />

        <Controller
          name="cooperativeMemberId"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              required
              {...omit(field, 'ref')}
              error={errors.cooperativeMemberId?.message}
              label="Cooperado"
              emptyOptionLabel="Escolha um Cooperado"
              id="cooperativeMemberId"
              options={cooperativeMemberOptions}
              disabled={disableFields}
            />
          )}
        />

        <Controller
          name="cooperativeId"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              required
              {...omit(field, 'ref')}
              label="Cooperativa"
              id="cooperativeId"
              options={cooperativeOptions}
              value={cooperativeId}
              disabled
            />
          )}
        />

        <Controller
          name="powerDistributionUnitId"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              required
              {...omit(field, 'ref')}
              error={errors.powerDistributionUnitId?.message}
              label="Concessionária"
              emptyOptionLabel="Escolha uma Concessionária"
              id="powerDistributionUnitId"
              options={powerDistributionUnitOptions}
              disabled={disableFields}
            />
          )}
        />
      </div>

      <p className="text-gray-dark400 font-bold text-sm pt-8 pb-6">Endereço</p>
      <div className="grid grid-cols-3 gap-8">
        <NumberOnlyField
          required
          error={errors.consumerUnitAddressPostalCode?.message}
          label="CEP"
          id="consumerUnitAddressPostalCode"
          {...register('consumerUnitAddressPostalCode', {
            required: 'Campo obrigatório',
            valueAsNumber: true,
          })}
          placeholder="81000000"
          minLength={8}
          maxLength={8}
        />
        <Controller
          name="consumerUnitAddressState"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <CountryStateSelectField
              required
              {...omit(field, 'ref')}
              error={errors.consumerUnitAddressState?.message}
              label="Estado"
              id="consumerUnitAddressState"
            />
          )}
        />
        <TextField
          required
          error={errors.consumerUnitAddressCity?.message}
          label="Cidade"
          id="consumerUnitAddressCity"
          {...register('consumerUnitAddressCity', { required: 'Campo obrigatório' })}
          placeholder="Ex.: Curitiba"
        />
        <TextField
          error={errors.consumerUnitAddressDistrict?.message}
          label="Bairro"
          id="consumerUnitAddressDistrict"
          {...register('consumerUnitAddressDistrict')}
          placeholder="Ex.: Centro"
        />
        <TextField
          required
          error={errors.consumerUnitAddressStreet?.message}
          label="Endereço"
          id="consumerUnitAddressStreet"
          {...register('consumerUnitAddressStreet', {
            required: 'Campo obrigatório',
          })}
          placeholder="Ex.: Comendador Franco 777"
        />
        <TextField
          error={errors.consumerUnitAddressComplement?.message}
          label="Complemento"
          id="consumerUnitAddressComplement"
          {...register('consumerUnitAddressComplement', {
            required: false,
          })}
          placeholder="Ex.: Sala 1"
        />
      </div>

      <p className="text-gray-dark400 font-bold text-sm pt-8 pb-6">
        Informações Técnicas
      </p>

      <Controller
        name="consumerUnitConsumptionGroupType"
        control={control}
        rules={{ required: 'Campo obrigatório' }}
        render={({ field }) => (
          <RadioField
            required
            {...omit(field, 'ref')}
            error={errors.consumerUnitConsumptionGroupType?.message}
            label="Grupo"
            id="consumerUnitConsumptionGroupType"
            options={[
              {
                label: 'Grupo A',
                value: 'A',
              },
              {
                label: 'Grupo B',
                value: 'B',
              },
            ]}
          />
        )}
      />

      <div className="grid grid-cols-12 gap-8 mt-5">
        <div className="col-start-1 col-end-5">
          <Controller
            name="consumerUnitConsumptionClass"
            control={control}
            rules={{ required: 'Campo obrigatório' }}
            render={({ field }) => (
              <SelectField
                required
                {...omit(field, 'ref')}
                error={errors.consumerUnitConsumptionClass?.message}
                label="Classe de Consumo"
                emptyOptionLabel="Escolha uma classe de consumo"
                id="consumerUnitConsumptionClass"
                options={consumerUnitConsumptionClassOptions}
              />
            )}
          />
        </div>
        <div className="col-start-5 col-end-9">
          <Controller
            name="consumerUnitModality"
            control={control}
            rules={{ required: 'Campo obrigatório' }}
            render={({ field }) => (
              <SelectField
                required
                {...omit(field, 'ref')}
                error={errors.consumerUnitModality?.message}
                label="Modalidade"
                emptyOptionLabel="Escolha uma Modalidade"
                id="consumerUnitModality"
                options={consumerUnitModalityOptions}
              />
            )}
          />
        </div>
        <div className="col-start-1 col-end-5">
          <TextField
            required
            error={errors.powerDistributionUnitCredentialsUser?.message}
            label="Usuário da Concessionária"
            id="powerDistributionUnitCredentialsUser"
            {...register('powerDistributionUnitCredentialsUser', {
              required: 'Campo obrigatório',
            })}
            placeholder="Ex.: exe@gmail.com ou 28.054.303/0001-06"
          />
        </div>
        <div className="col-start-5 col-end-9">
          <TextField
            error={errors.powerDistributionUnitCredentialsPasswordInput?.message}
            label="Senha da Concessionária"
            id="powerDistributionUnitCredentialsPasswordInput"
            {...register('powerDistributionUnitCredentialsPasswordInput')}
            placeholder="*******"
          />
        </div>
      </div>
    </>
  );
}
