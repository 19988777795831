import { useCallback, useEffect, useMemo, useState } from 'react';

import { MdClose } from 'react-icons/md';

import {
  Control,
  useForm,
  useWatch,
  FieldError,
  ErrorOption,
  UseFormReset,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { Button } from 'ui';
import TextField from 'ui/form/TextField';
import { ModalProps } from 'ui/models/overlay';
import { useModal } from 'ui/contexts/overlay/Modal';
import Accordion from 'ui/components/accordion/Accordion';
import SearchInput, { Option } from 'ui/components/form/SearchInput';
import MultipleSearchInput from 'ui/components/form/MultipleSearchInput';

import { RemoveButton } from 'dashboard/components/table/buttons';

import {
  Contact,
  Contacts,
  Entities,
  EntityTypes,
  ContactTypes,
} from 'contactGroups/models/contactGroups';

import {
  GenerationUnit,
  GenerationUnitSelect,
} from 'generationUnits/models/generationUnit';

import ContactGroupsDangerModal from '../modal/ContactGroupsDangerModal';

import { CooperativeMemberSelect } from 'cooperatives/models/cooperativeMember';

import { Cooperative, CooperativeSelect } from 'cooperatives/models/cooperative';

import { ConsumerUnit, ConsumerUnitsSelect } from 'consumerUnits/model/consumerUnit';

export type ContactGroupsFormFields = {
  groupName: string;
  entities: Entities;
  contacts: Contacts;
};

export type ContactsFormInputs = {
  ownerContactName: string;
  ownerContactEmail: string;
  ownerContactPhone: string;
  domainContactName: string;
  domainContactEmail: string;
  domainContactPhone: string;
  technicalContactName: string;
  technicalContactEmail: string;
  technicalContactPhone: string;
  juridicalContactName: string;
  juridicalContactEmail: string;
  juridicalContactPhone: string;
  financialContactName: string;
  financialContactEmail: string;
  financialContactPhone: string;
  administrativeContactName: string;
  administrativeContactEmail: string;
  administrativeContactPhone: string;
};

export type ContactGroupsFormInputs = ContactsFormInputs | ContactGroupsFormFields;

export type ContactGroupsFormErrors = {
  contacts?: FieldError;
  entities?: FieldError;
  groupName?: FieldError;
  cooperativeId?: FieldError;
  consumerUnitId?: FieldError;
  generationUnitId?: FieldError;
  ownerContactEmail?: FieldError;
  domainContactEmail?: FieldError;
  cooperativeMemberId?: FieldError;
  technicalContactEmail?: FieldError;
  juridicalContactEmail?: FieldError;
  financialContactEmail?: FieldError;
  administrativeContactEmail?: FieldError;
};

type Field = 'contacts' | 'entities' | 'groupName';

export const FORM_FIELDS: Field[] = ['contacts', 'entities', 'groupName'];

const DANGER_TOAST: ModalProps = {
  variant: 'danger',
  title: 'Atenção!',
  text: 'Você tem certeza que deseja cadastrar esse email como responsável por esse grupo de contatos? Não esqueça que esse email será o login da área do cliente e não será possível editar esse e-mail.',
};

export default function ContactGroupsFormFieldsComponent({
  reset,
  control,
  setValue,
  register,
  formErrors,
  isUpdatable,
  cooperatives,
  consumerUnits,
  generationUnits,
  cooperativeMembers,
  handleGetEntityList,
  handleGetContactList,
}: {
  isUpdatable?: boolean;
  cooperatives: CooperativeSelect[];
  formErrors: ContactGroupsFormErrors;
  consumerUnits: ConsumerUnitsSelect[];
  generationUnits: GenerationUnitSelect[];
  control: Control<ContactGroupsFormInputs>;
  cooperativeMembers: CooperativeMemberSelect[];
  reset: UseFormReset<ContactGroupsFormInputs>;
  setValue: UseFormSetValue<ContactGroupsFormInputs>;
  register: UseFormRegister<ContactGroupsFormInputs>;
  handleGetEntityList: (entities: Entities) => void;
  handleGetContactList: (contacts: Contacts) => void;
}) {
  const {
    ModalOverlay,
    showConfirm: modalShowConfirm,
    closeConfirm: modalCloseConfirm,
  } = useModal();

  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<ContactsFormInputs>();

  const REFERENCE_EMAIL_ERROR: ErrorOption = useMemo(() => {
    return {
      type: 'manual',
      message: 'E-mail com formato inválido.',
    };
  }, []);

  const regex = useMemo(() => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  }, []);

  const { fields: domainContactFields } = useFieldArray({
    control,
    name: 'contacts.domain',
  });

  const { fields: ownerContactFields } = useFieldArray({
    control,
    name: 'contacts.owner',
  });

  const { fields: technicalContactFields } = useFieldArray({
    control,
    name: 'contacts.technical',
  });

  const { fields: juridicalContactFields } = useFieldArray({
    control,
    name: 'contacts.juridical',
  });

  const { fields: financialContactFields } = useFieldArray({
    control,
    name: 'contacts.financial',
  });

  const { fields: administrativeContactFields } = useFieldArray({
    control,
    name: 'contacts.administrative',
  });

  const { fields: consumerUnitEntityFields } = useFieldArray({
    control,
    name: 'entities.consumerUnit',
  });

  const { fields: generationUnitEntityFields } = useFieldArray({
    control,
    name: 'entities.generationUnit',
  });

  const { fields: cooperativeEntityFields } = useFieldArray({
    control,
    name: 'entities.cooperative',
  });

  const [contacts, setContacts] = useState<Contacts>({
    owner: [],
    domain: [],
    technical: [],
    juridical: [],
    financial: [],
    administrative: [],
  });

  const [domainContact, setDomainContact] = useState<Contact>();

  const [entities, setEntities] = useState<Entities>({
    cooperative: cooperativeEntityFields || [],
    consumerUnit: consumerUnitEntityFields || [],
    generationUnit: generationUnitEntityFields || [],
  });

  const [selectOptions, setSelectOptions] = useState({
    cooperative: Array<Option<number>>() || [],
    consumerUnit: Array<Option<number>>() || [],
    generationUnit: Array<Option<number>>() || [],
    cooperativeMembers: Array<Option<string>>() || [],
  });

  const [entitySearchBarValue, setEntitySearchBarValue] = useState({
    cooperative: '',
    consumerUnit: '',
    generationUnit: '',
    cooperativeMember: '',
  });

  const [entitySearchBarSelectedOption, setEntitySearchBarSelectedOption] = useState(
    {
      cooperative: Array<Option<string | number>>() || [],
      consumerUnit: Array<Option<string | number>>() || [],
      generationUnit: Array<Option<string | number>>() || [],
      cooperativeMember: ({} as Option<string | number>) || {},
    }
  );

  const contactFieldsIsEmpty = useMemo(() => {
    return Object.values(contacts).every((contact) =>
      contact.every((contact: keyof Contacts) => !contact)
    );
  }, [contacts]);

  const entityFieldsIsEmpty = useMemo(() => {
    return Object.values(entities).every((entity) =>
      entity.every((entity: keyof Entities) => !entity)
    );
  }, [entities]);

  const filteredCooperatives = useMemo(() => {
    return entities?.cooperative?.filter((cooperative) => !!cooperative);
  }, [entities]);

  const filteredConsumerUnits = useMemo(() => {
    return entities?.consumerUnit?.filter((consumerUnit) => !!consumerUnit);
  }, [entities]);

  const filteredGenerationUnits = useMemo(() => {
    return entities?.generationUnit?.filter((generationUnit) => !!generationUnit);
  }, [entities]);

  const [
    ownerContactName,
    ownerContactEmail,
    ownerContactPhone,
    domainContactName = contacts?.domain[0]?.contactName || '',
    domainContactEmail = contacts?.domain[0]?.contactEmail || '',
    domainContactPhone = contacts?.domain[0]?.contactPhone || '',
    financialContactName,
    financialContactEmail,
    financialContactPhone,
    technicalContactName,
    technicalContactEmail,
    technicalContactPhone,
    juridicalContactName,
    juridicalContactEmail,
    juridicalContactPhone,
    administrativeContactName,
    administrativeContactEmail,
    administrativeContactPhone,
  ] = useWatch({
    control,
    name: [
      'ownerContactName',
      'ownerContactEmail',
      'ownerContactPhone',
      'domainContactName',
      'domainContactEmail',
      'domainContactPhone',
      'financialContactName',
      'financialContactEmail',
      'financialContactPhone',
      'technicalContactName',
      'technicalContactEmail',
      'technicalContactPhone',
      'juridicalContactName',
      'juridicalContactEmail',
      'juridicalContactPhone',
      'administrativeContactName',
      'administrativeContactEmail',
      'administrativeContactPhone',
    ],
  });

  useEffect(() => {
    isUpdatable &&
      contactFieldsIsEmpty &&
      setContacts((prev) => ({
        ...prev,
        owner: ownerContactFields,
        domain: domainContactFields,
        technical: technicalContactFields,
        juridical: juridicalContactFields,
        financial: financialContactFields,
        administrative: administrativeContactFields,
      }));
  }, [
    isUpdatable,
    ownerContactFields,
    domainContactFields,
    contactFieldsIsEmpty,
    financialContactFields,
    juridicalContactFields,
    technicalContactFields,
    administrativeContactFields,
  ]);

  useEffect(() => {
    isUpdatable &&
      entityFieldsIsEmpty &&
      setEntities((prev) => ({
        ...prev,
        cooperative: cooperativeEntityFields,
        consumerUnit: consumerUnitEntityFields,
        generationUnit: generationUnitEntityFields,
      }));
  }, [
    isUpdatable,
    entityFieldsIsEmpty,
    cooperativeEntityFields,
    consumerUnitEntityFields,
    generationUnitEntityFields,
  ]);

  useEffect(() => {
    !!consumerUnits &&
      setSelectOptions((prev) => ({
        ...prev,
        consumerUnit:
          consumerUnits.map((consumerUnit) => ({
            key: consumerUnit.id,
            value: `${consumerUnit?.consumerUnitPowerDistributionUnitIdentifier} - ${consumerUnit?.cooperativeMember?.cooperativeMemberLegalName}`,
          })) || [],
      }));
  }, [consumerUnits]);

  useEffect(() => {
    !!generationUnits &&
      setSelectOptions((prev) => ({
        ...prev,
        generationUnit:
          generationUnits.map((generationUnit) => ({
            key: generationUnit.id,
            value: `${generationUnit.generationUnitLegalName} - ${generationUnit.powerDistributionUnit.powerDistributionUnitLegalName}`,
          })) || [],
      }));
  }, [generationUnits]);

  useEffect(() => {
    !!cooperatives &&
      setSelectOptions((prev) => ({
        ...prev,
        cooperative:
          cooperatives.map((cooperative) => ({
            key: cooperative.id,
            value: `${cooperative.cooperativeLegalName} - ${cooperative.cooperativeAddressState}`,
          })) || [],
      }));
  }, [cooperatives]);

  useEffect(() => {
    !!cooperativeMembers &&
      setSelectOptions((prev) => ({
        ...prev,
        cooperativeMembers:
          cooperativeMembers.map((cooperativeMember) => ({
            key: String(cooperativeMember.id),
            value: `${cooperativeMember.cooperativeMemberLegalName}`,
          })) || [],
      }));
  }, [cooperativeMembers]);

  const handleClearEntityInputs = useCallback(
    (entityType: keyof Entities) => {
      setEntitySearchBarValue({
        ...entitySearchBarValue,
        [entityType]: '',
      });

      setEntitySearchBarSelectedOption({
        ...entitySearchBarSelectedOption,
        [entityType]: [],
      });
    },
    [entitySearchBarSelectedOption, entitySearchBarValue]
  );

  const handleChangeDomainContact = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      return setValue(e.target.name as any, e.target.value);
    },
    [setValue]
  );

  const handleChangeEntity = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, entityType: EntityTypes) => {
      setEntitySearchBarValue({
        ...entitySearchBarValue,
        [entityType]: e.target?.value || '',
      });
    },
    [entitySearchBarValue]
  );

  const handleClearContactInputs = useCallback(
    (contactType: ContactTypes) => {
      return reset({
        [`${contactType.toLowerCase()}ContactName`]: '',
        [`${contactType.toLowerCase()}ContactEmail`]: '',
        [`${contactType.toLowerCase()}ContactPhone`]: '',
      });
    },
    [reset]
  );

  const findSelectedConsumerUnit = useCallback(
    (id: string) => {
      return consumerUnits.find((consumerUnit) => String(consumerUnit.id) === id);
    },
    [consumerUnits]
  );

  const findSelectedGenerationUnit = useCallback(
    (id: string) => {
      return generationUnits.find(
        (generationUnit) => String(generationUnit.id) === id
      );
    },
    [generationUnits]
  );

  const findSelectedCooperative = useCallback(
    (id: string) => {
      return cooperatives.find((cooperative) => String(cooperative.id) === id);
    },
    [cooperatives]
  );

  const handleCooperativeMemberSearchBarSelectItem = useCallback(
    (item: Option<string | number>) => {
      setEntitySearchBarSelectedOption({
        ...entitySearchBarSelectedOption,
        cooperativeMember: item,
      });

      setEntitySearchBarValue({
        ...entitySearchBarValue,
        cooperativeMember: item.value,
      });
    },
    [entitySearchBarSelectedOption, entitySearchBarValue]
  );

  const handleEntitySearchBarSelectItem = useCallback(
    (item: Option<string | number>, entityType: keyof Entities) => {
      setEntitySearchBarSelectedOption({
        ...entitySearchBarSelectedOption,
        [entityType]: entitySearchBarSelectedOption[entityType]?.some(
          (option) => option.key === item.key
        )
          ? entitySearchBarSelectedOption[entityType]?.filter(
              (option) => option.key !== item.key
            )
          : [...(entitySearchBarSelectedOption[entityType] || []), item],
      });
    },
    [entitySearchBarSelectedOption]
  );

  const handleSelectAllOptions = useCallback(
    (entityType: keyof Entities) => {
      const selectedOptions =
        entitySearchBarValue[entityType]?.length > 0
          ? selectOptions[entityType]?.filter((option) =>
              option.value
                ?.toLowerCase()
                .includes(entitySearchBarValue[entityType]?.toLowerCase())
            )
          : selectOptions[entityType];

      setEntitySearchBarSelectedOption({
        ...entitySearchBarSelectedOption,
        [entityType]:
          entitySearchBarSelectedOption[entityType]?.length ===
          selectedOptions?.length
            ? []
            : selectedOptions,
      });
    },
    [entitySearchBarSelectedOption, entitySearchBarValue, selectOptions]
  );

  const handleAddEntity = useMemo(
    () => (entityType: keyof Entities) => {
      handleClearEntityInputs(entityType);

      switch (entityType) {
        case EntityTypes.COOPERATIVE:
          const cooperative = entitySearchBarSelectedOption.cooperative?.map(
            (option) => findSelectedCooperative(option.key as string)
          );

          entitySearchBarSelectedOption.cooperative?.map((selectedOption) =>
            setSelectOptions((prev) => ({
              ...prev,
              cooperative: prev.cooperative.filter(
                (option) => option.key !== selectedOption.key
              ),
            }))
          );

          return cooperative?.map((cooperative) =>
            setEntities((prev) => ({
              ...prev,
              cooperative: [
                ...prev.cooperative,
                {
                  id: cooperative?.id,
                  cooperativeLegalName: cooperative?.cooperativeLegalName,
                  cooperativeDocumentId: cooperative?.cooperativeDocumentId,
                  cooperativeAddressState: cooperative?.cooperativeAddressState,
                  financialAccount: {
                    financialAccountLegalName:
                      cooperative?.financialAccount?.financialAccountLegalName,
                  },
                } as Cooperative,
              ],
            }))
          );

        case EntityTypes.CONSUMER_UNIT:
          const consumerUnit = entitySearchBarSelectedOption.consumerUnit?.map(
            (option) => findSelectedConsumerUnit(option.key as string)
          );

          entitySearchBarSelectedOption.consumerUnit?.map((selectedOption) =>
            setSelectOptions((prev) => ({
              ...prev,
              consumerUnit: prev.consumerUnit.filter(
                (option) => option.key !== selectedOption.key
              ),
            }))
          );

          return consumerUnit?.map((consumerUnit) =>
            setEntities((prev) => ({
              ...prev,
              consumerUnit: [
                ...prev.consumerUnit,
                {
                  id: consumerUnit?.id,
                  cooperativeMember: {
                    cooperativeMemberLegalName:
                      consumerUnit?.cooperativeMember?.cooperativeMemberLegalName,
                    cooperativeMemberDocumentId:
                      consumerUnit?.cooperativeMember?.cooperativeMemberDocumentId,
                  },
                  consumerUnitPowerDistributionUnitIdentifier:
                    consumerUnit?.consumerUnitPowerDistributionUnitIdentifier,
                  consumerUnitAddressCity: consumerUnit?.consumerUnitAddressCity,
                  consumerUnitAddressDistrict:
                    consumerUnit?.consumerUnitAddressDistrict,
                  consumerUnitAddressState: consumerUnit?.consumerUnitAddressState,
                  powerDistributionUnit: {
                    powerDistributionUnitLegalName:
                      consumerUnit?.powerDistributionUnit
                        ?.powerDistributionUnitLegalName,
                  },
                } as ConsumerUnit,
              ],
            }))
          );

        case EntityTypes.GENERATION_UNIT:
          const generationUnit = entitySearchBarSelectedOption.generationUnit?.map(
            (option) => findSelectedGenerationUnit(option.key as string)
          );

          entitySearchBarSelectedOption.generationUnit?.map((selectedOption) =>
            setSelectOptions((prev) => ({
              ...prev,
              generationUnit: prev.generationUnit.filter(
                (option) => option.key !== selectedOption.key
              ),
            }))
          );

          return generationUnit?.map((generationUnit) =>
            setEntities((prev) => ({
              ...prev,
              generationUnit: [
                ...prev.generationUnit,
                {
                  id: generationUnit?.id,
                  generationUnitLegalName: generationUnit?.generationUnitLegalName,
                  financialAccount: {
                    financialAccountLegalName:
                      generationUnit?.financialAccount?.financialAccountLegalName,
                  },
                  powerDistributionUnit: {
                    powerDistributionUnitLegalName:
                      generationUnit?.powerDistributionUnit
                        ?.powerDistributionUnitLegalName,
                  },
                } as GenerationUnit,
              ],
            }))
          );
      }
    },
    [
      handleClearEntityInputs,
      findSelectedCooperative,
      findSelectedConsumerUnit,
      findSelectedGenerationUnit,
      entitySearchBarSelectedOption.cooperative,
      entitySearchBarSelectedOption.consumerUnit,
      entitySearchBarSelectedOption.generationUnit,
    ]
  );

  const handleRemoveEntity = useCallback(
    (index: number, entityType: keyof Entities) => {
      const { consumerUnit, cooperative, generationUnit } = entities;

      switch (entityType) {
        case EntityTypes.CONSUMER_UNIT:
          const consumerUnitToRemove = consumerUnit?.find(
            (_, consumerUnitIndex) => consumerUnitIndex === index
          );

          setSelectOptions((prev) => ({
            ...prev,
            consumerUnit: [
              ...prev.consumerUnit,
              {
                key: consumerUnitToRemove?.id as number,
                value: `${consumerUnitToRemove?.consumerUnitPowerDistributionUnitIdentifier} - ${consumerUnitToRemove?.cooperativeMember?.cooperativeMemberLegalName}`,
              },
            ],
          }));

          setEntities((prev) => ({
            ...prev,
            consumerUnit: prev.consumerUnit.filter(
              (_, consumerUnitIndex) => consumerUnitIndex !== index
            ),
          }));

          break;

        case EntityTypes.COOPERATIVE:
          const cooperativeToRemove = cooperative?.find(
            (_, cooperativeIndex) => cooperativeIndex === index
          );

          setSelectOptions((prev) => ({
            ...prev,
            cooperative: [
              ...prev.cooperative,
              {
                key: cooperativeToRemove?.id as number,
                value: `${cooperativeToRemove?.cooperativeLegalName} - ${cooperativeToRemove?.cooperativeAddressState}`,
              },
            ],
          }));

          setEntities((prev) => ({
            ...prev,
            cooperative: prev.cooperative.filter(
              (_, cooperativeIndex) => cooperativeIndex !== index
            ),
          }));

          break;

        case EntityTypes.GENERATION_UNIT:
          const generationUnitToRemove = generationUnit?.find(
            (_, generationUnitIndex) => generationUnitIndex === index
          );

          setSelectOptions((prev) => ({
            ...prev,
            generationUnit: [
              ...prev.generationUnit,
              {
                key: generationUnitToRemove?.id as number,
                value: `${generationUnitToRemove?.generationUnitLegalName} - ${generationUnitToRemove?.powerDistributionUnit?.powerDistributionUnitLegalName}`,
              },
            ],
          }));

          setEntities((prev) => ({
            ...prev,
            generationUnit: prev.generationUnit.filter(
              (_, generationUnitIndex) => generationUnitIndex !== index
            ),
          }));

          break;
      }
    },
    [entities]
  );

  const handleAllConsumerUnitOptionsAreSelected = useCallback(() => {
    const { consumerUnit } = entities;

    const consumerUnitOptionsLength = consumerUnits.length;

    return (
      consumerUnit?.length > 0 && consumerUnit?.length < consumerUnitOptionsLength
    );
  }, [consumerUnits.length, entities]);

  const onClickCloseModal = useCallback(() => {
    modalCloseConfirm();

    setDomainContact({} as Contact);
  }, [modalCloseConfirm]);

  const handleShowConfirm = useCallback(
    (contactName: string, contactEmail: string, contactPhone: string) => {
      modalShowConfirm();

      setDomainContact({
        contactName,
        contactEmail,
        contactPhone,
      } as Contact);
    },
    [modalShowConfirm]
  );

  const onClickContinue = useCallback(() => {
    modalCloseConfirm();

    domainContact &&
      setContacts((prev) => ({
        ...prev,
        domain: [
          {
            contactName: domainContact.contactName,
            contactEmail: domainContact.contactEmail,
            contactPhone: domainContact.contactPhone,
          } as Contact,
        ],
      }));

    setDomainContact({} as Contact);
  }, [modalCloseConfirm, domainContact]);

  useMemo(() => {
    const EMAILS_TO_VALIDATE = {
      ownerContactEmail,
      domainContactEmail,
      financialContactEmail,
      juridicalContactEmail,
      technicalContactEmail,
      administrativeContactEmail,
    };

    Object.entries(EMAILS_TO_VALIDATE).map(([key, value]) => {
      if (value) {
        return !regex.test(value)
          ? setError(key as keyof ContactsFormInputs, REFERENCE_EMAIL_ERROR)
          : clearErrors(key as keyof ContactsFormInputs);
      }

      return () => {};
    });

    return () => {};
  }, [
    regex,
    setError,
    clearErrors,
    ownerContactEmail,
    domainContactEmail,
    financialContactEmail,
    juridicalContactEmail,
    technicalContactEmail,
    REFERENCE_EMAIL_ERROR,
    administrativeContactEmail,
  ]);

  useMemo(() => {
    if (!!entitySearchBarSelectedOption.cooperativeMember?.value) {
      const filteredConsumerUnits = selectOptions.consumerUnit?.filter(
        (consumerUnit) =>
          consumerUnit?.value?.includes(
            entitySearchBarSelectedOption.cooperativeMember.value
          )
      );

      if (filteredConsumerUnits?.length > 0) {
        setEntitySearchBarValue((prev) => ({
          ...prev,
          [EntityTypes.CONSUMER_UNIT]:
            entitySearchBarSelectedOption.cooperativeMember.value,
        }));

        setEntitySearchBarSelectedOption((prev) => ({
          ...prev,
          [EntityTypes.CONSUMER_UNIT]: filteredConsumerUnits,
        }));

        return () => {};
      }

      return () => {};
    } else {
      handleClearEntityInputs(EntityTypes.CONSUMER_UNIT);
    }

    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions.consumerUnit, entitySearchBarSelectedOption.cooperativeMember]);

  useEffect(() => {
    handleGetContactList(contacts);
  }, [contacts, handleGetContactList]);

  useEffect(() => {
    handleGetEntityList(entities);
  }, [entities, handleGetEntityList]);

  const HAS_OWNER_EMPTY_INPUTS =
    !ownerContactName || !ownerContactEmail || !ownerContactPhone;

  const HAS_DOMAIN_EMPTY_INPUTS =
    !domainContactName || !domainContactEmail || !domainContactPhone;

  const HAS_TECHNICAL_EMPTY_INPUTS =
    !technicalContactName || !technicalContactEmail || !technicalContactPhone;

  const HAS_JURIDICAL_EMPTY_INPUTS =
    !juridicalContactName || !juridicalContactEmail || !juridicalContactPhone;

  const HAS_FINANCIAL_EMPTY_INPUTS =
    !financialContactName || !financialContactEmail || !financialContactPhone;

  const HAS_ADMINISTRATIVE_EMPTY_INPUTS =
    !administrativeContactName ||
    !administrativeContactEmail ||
    !administrativeContactPhone;

  const HAS_CONSUMER_UNIT_EMPTY_INPUTS =
    !entitySearchBarSelectedOption?.consumerUnit?.length;

  const HAS_COOPERATIVE_EMPTY_INPUTS =
    !entitySearchBarSelectedOption?.cooperative?.length;

  const HAS_GENERATION_UNIT_EMPTY_INPUTS =
    !entitySearchBarSelectedOption?.generationUnit?.length;

  const HAS_AT_LEAST_ONE_DOMAIN_CONTACT = contacts.domain?.length > 0;

  return (
    <>
      <div className="flex flex-col w-full gap-4">
        <div className="grid grid-cols-6">
          <TextField
            required
            id={'groupName'}
            label="Nome do grupo"
            placeholder="Ex: Nome"
            {...register('groupName', {
              required: 'Campo obrigatório',
            })}
            error={formErrors?.groupName?.message}
          />
        </div>

        <div className="border-b-2 border-b-gray-dark600 w-full mt-3" />

        <div className="flex w-full flex-col gap-4">
          <p className="py-4 font-bold text-sm text-gray-dark400">
            Dados de contato
          </p>
          <Accordion
            required
            title="Proprietários e login"
            error={formErrors.contacts?.message}
            length={contacts.domain?.length + contacts.owner?.length}
          >
            <div className="flex flex-col items-start w-full">
              <p className="font-medium text-sm text-gray-dark400 py-2">
                Cadastre o proprietário principal e responsável pelo login da área do
                cliente*
              </p>
              <div className="w-full flex items-center justify-center gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-between w-full">
                  <TextField
                    placeholder="Nome*"
                    id="domainContactName"
                    wrapperClassName="w-full"
                    value={domainContactName}
                    {...register('domainContactName')}
                    onChange={handleChangeDomainContact}
                  />

                  <TextField
                    type="email"
                    placeholder="Email*"
                    id="domainContactEmail"
                    wrapperClassName="w-full"
                    {...register('domainContactEmail')}
                    disabled={HAS_AT_LEAST_ONE_DOMAIN_CONTACT}
                    error={errors?.domainContactEmail?.message}
                  />

                  <TextField
                    mask="mobilePhone"
                    id="domainContactPhone"
                    wrapperClassName="w-full"
                    value={domainContactPhone}
                    placeholder="Telefone (Celular)*"
                    {...register('domainContactPhone')}
                    onChange={handleChangeDomainContact}
                  />
                </div>
                {HAS_AT_LEAST_ONE_DOMAIN_CONTACT ? (
                  <Button
                    size="sm"
                    type="button"
                    disabled={HAS_DOMAIN_EMPTY_INPUTS}
                    className="flex items-center justify-center w-24"
                    onClick={() =>
                      setContacts((prev) => ({
                        ...prev,
                        domain: [
                          {
                            contactName: domainContactName,
                            contactEmail: domainContactEmail,
                            contactPhone: domainContactPhone,
                          } as Contact,
                        ],
                      }))
                    }
                  >
                    Atualizar
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    type="button"
                    disabled={HAS_DOMAIN_EMPTY_INPUTS}
                    className="flex items-center justify-center w-24"
                    onClick={() =>
                      handleShowConfirm(
                        domainContactName,
                        domainContactEmail,
                        domainContactPhone
                      )
                    }
                  >
                    Adicionar
                  </Button>
                )}
              </div>
              <p className="font-medium text-sm text-gray-dark400 py-2">
                Outros proprietários
              </p>
              <div className="w-full flex items-center justify-center gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-between w-full">
                  <TextField
                    placeholder="Nome"
                    id="ownerContactName"
                    wrapperClassName="w-full"
                    {...register('ownerContactName')}
                  />

                  <TextField
                    placeholder="Email"
                    id="ownerContactEmail"
                    wrapperClassName="w-full"
                    {...register('ownerContactEmail')}
                    error={errors?.ownerContactEmail?.message}
                  />

                  <TextField
                    mask="mobilePhone"
                    id="ownerContactPhone"
                    wrapperClassName="w-full"
                    {...register('ownerContactPhone')}
                    placeholder="Telefone (Celular)"
                  />
                </div>

                <Button
                  size="sm"
                  type="button"
                  className="flex items-center justify-center w-24"
                  disabled={HAS_OWNER_EMPTY_INPUTS}
                  onClick={() => {
                    setContacts((prev) => ({
                      ...prev,
                      owner: [
                        ...prev.owner,
                        {
                          contactName: ownerContactName,
                          contactEmail: ownerContactEmail,
                          contactPhone: ownerContactPhone,
                        } as Contact,
                      ],
                    }));

                    handleClearContactInputs(ContactTypes.OWNER);
                  }}
                >
                  Adicionar
                </Button>
              </div>
              {HAS_AT_LEAST_ONE_DOMAIN_CONTACT && (
                <div className="flex flex-col items-start w-full">
                  <div className="flex flex-col w-full items-center justify-start">
                    <div className="flex w-full items-center justify-start">
                      <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                        <p className="text-gray-dark400 font-medium text-sm w-full flex items-center justify-start">
                          {contacts.domain[0]?.contactName}
                        </p>

                        <p className="text-gray-dark400 font-medium text-sm w-full flex items-center justify-start">
                          {contacts.domain[0]?.contactEmail}
                        </p>

                        <p className="text-gray-dark400 font-medium text-sm w-full flex items-center justify-start">
                          {contacts.domain[0]?.contactPhone}
                        </p>
                      </div>

                      <div
                        className="flex items-center justify-center px-10"
                        style={{
                          width: '6rem',
                        }}
                      />
                    </div>
                    <div
                      className="border-b w-full h-1"
                      style={{
                        borderColor: '#333333',
                      }}
                    />
                  </div>
                </div>
              )}
              {contacts.owner?.length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {contacts.owner.map((contact, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + contact.contactName}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactEmail}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactPhone}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() =>
                                setContacts(
                                  (prev) =>
                                    ({
                                      ...prev,
                                      owner: prev.owner.filter(
                                        (_, currentIndex) => currentIndex !== index
                                      ),
                                    } as Contacts)
                                )
                              }
                            />
                          </div>
                        </div>
                        {index !== contacts.owner.length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
          <Accordion title="Financeiro" length={contacts.financial?.length}>
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-center gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-between w-full">
                  <TextField
                    placeholder="Nome"
                    wrapperClassName="w-full"
                    id="financialContactName"
                    {...register('financialContactName')}
                  />

                  <TextField
                    placeholder="Email"
                    wrapperClassName="w-full"
                    id="financialContactEmail"
                    {...register('financialContactEmail')}
                    error={errors?.financialContactEmail?.message}
                  />

                  <TextField
                    mask="mobilePhone"
                    wrapperClassName="w-full"
                    id="financialContactPhone"
                    {...register('financialContactPhone')}
                    placeholder="Telefone (Celular)"
                  />
                </div>

                <Button
                  size="sm"
                  type="button"
                  className="flex items-center justify-center w-24"
                  disabled={HAS_FINANCIAL_EMPTY_INPUTS}
                  onClick={() => {
                    setContacts((prev) => ({
                      ...prev,
                      financial: [
                        ...prev.financial,
                        {
                          contactName: financialContactName,
                          contactEmail: financialContactEmail,
                          contactPhone: financialContactPhone,
                        } as Contact,
                      ],
                    }));

                    handleClearContactInputs(ContactTypes.FINANCIAL);
                  }}
                >
                  Adicionar
                </Button>
              </div>
              {contacts.financial?.length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {contacts.financial.map((contact, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + contact.contactName}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactEmail}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactPhone}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() =>
                                setContacts(
                                  (prev) =>
                                    ({
                                      ...prev,
                                      financial: prev.financial.filter(
                                        (_, currentIndex) => currentIndex !== index
                                      ),
                                    } as Contacts)
                                )
                              }
                            />
                          </div>
                        </div>
                        {index !== contacts.financial.length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
          <Accordion title="Responsável Técnico" length={contacts.technical?.length}>
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-center gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-between w-full">
                  <TextField
                    placeholder="Nome"
                    id="technicalContactName"
                    wrapperClassName="w-full"
                    {...register('technicalContactName')}
                  />

                  <TextField
                    placeholder="Email"
                    wrapperClassName="w-full"
                    id="technicalContactEmail"
                    {...register('technicalContactEmail')}
                    error={errors?.technicalContactEmail?.message}
                  />

                  <TextField
                    mask="mobilePhone"
                    wrapperClassName="w-full"
                    id="technicalContactPhone"
                    {...register('technicalContactPhone')}
                    placeholder="Telefone (Celular)"
                  />
                </div>

                <Button
                  size="sm"
                  type="button"
                  className="flex items-center justify-center w-24"
                  disabled={HAS_TECHNICAL_EMPTY_INPUTS}
                  onClick={() => {
                    setContacts((prev) => ({
                      ...prev,
                      technical: [
                        ...prev.technical,
                        {
                          contactName: technicalContactName,
                          contactEmail: technicalContactEmail,
                          contactPhone: technicalContactPhone,
                        } as Contact,
                      ],
                    }));

                    handleClearContactInputs(ContactTypes.TECHNICAL);
                  }}
                >
                  Adicionar
                </Button>
              </div>
              {contacts.technical?.length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {contacts.technical.map((contact, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + contact.contactName}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactEmail}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactPhone}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() =>
                                setContacts(
                                  (prev) =>
                                    ({
                                      ...prev,
                                      technical: prev.technical.filter(
                                        (_, currentIndex) => currentIndex !== index
                                      ),
                                    } as Contacts)
                                )
                              }
                            />
                          </div>
                        </div>
                        {index !== contacts.technical.length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
          <Accordion title="Jurídico" length={contacts.juridical?.length}>
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-center gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-between w-full">
                  <TextField
                    placeholder="Nome"
                    id="juridicalContactName"
                    wrapperClassName="w-full"
                    {...register('juridicalContactName')}
                  />

                  <TextField
                    placeholder="Email"
                    wrapperClassName="w-full"
                    id="juridicalContactEmail"
                    {...register('juridicalContactEmail')}
                    error={errors?.juridicalContactEmail?.message}
                  />

                  <TextField
                    mask="mobilePhone"
                    wrapperClassName="w-full"
                    id="juridicalContactPhone"
                    placeholder="Telefone (Celular)"
                    {...register('juridicalContactPhone')}
                  />
                </div>

                <Button
                  size="sm"
                  type="button"
                  className="flex items-center justify-center w-24"
                  disabled={HAS_JURIDICAL_EMPTY_INPUTS}
                  onClick={() => {
                    setContacts((prev) => ({
                      ...prev,
                      juridical: [
                        ...prev.juridical,
                        {
                          contactName: juridicalContactName,
                          contactEmail: juridicalContactEmail,
                          contactPhone: juridicalContactPhone,
                        } as Contact,
                      ],
                    }));

                    handleClearContactInputs(ContactTypes.JURIDICAL);
                  }}
                >
                  Adicionar
                </Button>
              </div>
              {contacts.juridical?.length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {contacts.juridical.map((contact, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + contact.contactName}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactEmail}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactPhone}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() =>
                                setContacts(
                                  (prev) =>
                                    ({
                                      ...prev,
                                      juridical: prev.juridical.filter(
                                        (_, currentIndex) => currentIndex !== index
                                      ),
                                    } as Contacts)
                                )
                              }
                            />
                          </div>
                        </div>
                        {index !== contacts.juridical.length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
          <Accordion title="Administrativo" length={contacts.administrative?.length}>
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-center gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-between w-full">
                  <TextField
                    placeholder="Nome"
                    wrapperClassName="w-full"
                    id="administrativeContactName"
                    {...register('administrativeContactName')}
                  />

                  <TextField
                    placeholder="Email"
                    wrapperClassName="w-full"
                    id="administrativeContactEmail"
                    {...register('administrativeContactEmail')}
                    error={errors?.administrativeContactEmail?.message}
                  />

                  <TextField
                    mask="mobilePhone"
                    wrapperClassName="w-full"
                    id="administrativeContactPhone"
                    placeholder="Telefone (Celular)"
                    {...register('administrativeContactPhone')}
                  />
                </div>

                <Button
                  size="sm"
                  type="button"
                  className="flex items-center justify-center w-24"
                  disabled={HAS_ADMINISTRATIVE_EMPTY_INPUTS}
                  onClick={() => {
                    setContacts((prev) => ({
                      ...prev,
                      administrative: [
                        ...prev.administrative,
                        {
                          contactName: administrativeContactName,
                          contactEmail: administrativeContactEmail,
                          contactPhone: administrativeContactPhone,
                        } as Contact,
                      ],
                    }));

                    handleClearContactInputs(ContactTypes.ADMINISTRATIVE);
                  }}
                >
                  Adicionar
                </Button>
              </div>
              {contacts.administrative?.length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {contacts.administrative.map((contact, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + contact.contactName}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactEmail}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactPhone}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() =>
                                setContacts(
                                  (prev) =>
                                    ({
                                      ...prev,
                                      administrative: prev.administrative.filter(
                                        (_, currentIndex) => currentIndex !== index
                                      ),
                                    } as Contacts)
                                )
                              }
                            />
                          </div>
                        </div>
                        {index !== contacts.administrative.length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
        </div>

        <div className="border-b-2 border-b-gray-dark600 w-full mt-3" />

        <div className="flex w-full flex-col gap-4">
          <div className="py-4 flex flex-col items-start">
            <p className="font-bold text-sm text-gray-dark400">
              Vincular entidades*
            </p>
            <p className="font-light text-sm text-gray-dark400">
              Adicione as entidades vinculadas aos contatos
            </p>
            {!!formErrors.entities && (
              <p className="font-light text-sm text-red-500">
                {formErrors.entities?.message}
              </p>
            )}
            {handleAllConsumerUnitOptionsAreSelected() && (
              <p className="font-light text-sm text-red-500">
                *As unidades que não foram selecionadas a esse grupo, precisam ser
                adicionadas a um grupo para serem visíveis na área de cliente
              </p>
            )}
          </div>
          <Accordion
            title="Unidade Consumidora"
            length={filteredConsumerUnits?.length}
          >
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-between gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-center items-center w-full">
                  <SearchInput
                    id="cooperativeMemberId"
                    placeholder="Escolha um Cooperado"
                    value={entitySearchBarValue.cooperativeMember}
                    options={selectOptions.cooperativeMembers}
                    error={formErrors.cooperativeMemberId?.message}
                    onChange={(e) =>
                      handleChangeEntity(e, EntityTypes.COOPERATIVE_MEMBER)
                    }
                    selectItem={handleCooperativeMemberSearchBarSelectItem}
                    selectedOption={entitySearchBarSelectedOption.cooperativeMember}
                  />

                  <MultipleSearchInput
                    isAllSelectable
                    id="consumerUnitId"
                    name="consumerUnitId"
                    placeholder="Procurar UC"
                    options={selectOptions.consumerUnit}
                    error={formErrors?.consumerUnitId?.message}
                    value={entitySearchBarValue.consumerUnit}
                    selectedOption={entitySearchBarSelectedOption.consumerUnit || []}
                    onChange={(e) =>
                      handleChangeEntity(e, EntityTypes.CONSUMER_UNIT)
                    }
                    selectItem={(item) =>
                      handleEntitySearchBarSelectItem(
                        item,
                        EntityTypes.CONSUMER_UNIT
                      )
                    }
                    selectAll={() =>
                      handleSelectAllOptions(EntityTypes.CONSUMER_UNIT)
                    }
                  />

                  <Button
                    size="sm"
                    type="button"
                    disabled={HAS_CONSUMER_UNIT_EMPTY_INPUTS}
                    className="flex items-center justify-center w-24"
                    onClick={() => handleAddEntity(EntityTypes.CONSUMER_UNIT)}
                  >
                    Adicionar
                  </Button>
                </div>
              </div>
              {filteredConsumerUnits?.length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {filteredConsumerUnits?.map((entity, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + entity?.id}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div
                            className="grid items-center gap-6 justify-start w-full py-6"
                            style={{
                              gridTemplateColumns: '.5fr 1fr .75fr .75fr',
                            }}
                          >
                            <p className="text-gray-dark500 font-bold text-sm w-full flex items-center justify-start text-left">
                              {entity?.consumerUnitPowerDistributionUnitIdentifier}
                            </p>

                            <p
                              className="text-gray-dark500 font-bold text-sm w-full items-center justify-start"
                              style={{
                                overflow: 'hidden',
                                textAlign: 'left',
                                whiteSpace: 'nowrap',
                                display: 'inline-block',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {entity?.cooperativeMember?.cooperativeMemberLegalName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {
                                entity?.powerDistributionUnit
                                  ?.powerDistributionUnitLegalName
                              }
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {entity?.consumerUnitAddressDistrict},{' '}
                              {entity?.consumerUnitAddressCity} -{' '}
                              {entity?.consumerUnitAddressState}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() =>
                                handleRemoveEntity(index, EntityTypes.CONSUMER_UNIT)
                              }
                            />
                          </div>
                        </div>
                        {index !== filteredConsumerUnits.length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
          <Accordion
            title="Unidade Geradora"
            length={filteredGenerationUnits?.length}
          >
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-between gap-4">
                <div className="flex gap-4 py-4 justify-center items-center w-full max-w-3xl">
                  <MultipleSearchInput
                    isAllSelectable
                    id="generationUnitId"
                    name="generationUnitId"
                    placeholder="Procurar UG"
                    options={selectOptions.generationUnit}
                    error={formErrors?.generationUnitId?.message}
                    value={entitySearchBarValue.generationUnit}
                    selectedOption={
                      entitySearchBarSelectedOption.generationUnit || []
                    }
                    onChange={(e) =>
                      handleChangeEntity(e, EntityTypes.GENERATION_UNIT)
                    }
                    selectItem={(item) =>
                      handleEntitySearchBarSelectItem(
                        item,
                        EntityTypes.GENERATION_UNIT
                      )
                    }
                    selectAll={() =>
                      handleSelectAllOptions(EntityTypes.GENERATION_UNIT)
                    }
                  />

                  <Button
                    size="sm"
                    type="button"
                    disabled={HAS_GENERATION_UNIT_EMPTY_INPUTS}
                    className="flex items-center justify-center w-24"
                    onClick={() => handleAddEntity(EntityTypes.GENERATION_UNIT)}
                  >
                    Adicionar
                  </Button>
                </div>
              </div>
              {filteredGenerationUnits?.length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {filteredGenerationUnits?.map((entity, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + entity?.id}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-6 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-bold text-sm w-full flex items-center justify-start">
                              {entity?.generationUnitLegalName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {entity?.financialAccount?.financialAccountLegalName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              Concessionária:{' '}
                              {
                                entity?.powerDistributionUnit
                                  ?.powerDistributionUnitLegalName
                              }
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() =>
                                handleRemoveEntity(
                                  index,
                                  EntityTypes.GENERATION_UNIT
                                )
                              }
                            />
                          </div>
                        </div>
                        {index !== filteredGenerationUnits?.length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
          <Accordion title="Cooperativa" length={filteredCooperatives?.length}>
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-between gap-4">
                <div className="flex gap-4 py-4 justify-center items-center w-full max-w-3xl">
                  <MultipleSearchInput
                    isAllSelectable
                    id="cooperativeId"
                    placeholder="Procurar Cooperativa"
                    options={selectOptions.cooperative}
                    error={formErrors?.cooperativeId?.message}
                    value={entitySearchBarValue.cooperative}
                    selectedOption={entitySearchBarSelectedOption.cooperative || []}
                    onChange={(e) => handleChangeEntity(e, EntityTypes.COOPERATIVE)}
                    selectItem={(item) =>
                      handleEntitySearchBarSelectItem(item, EntityTypes.COOPERATIVE)
                    }
                    selectAll={() => handleSelectAllOptions(EntityTypes.COOPERATIVE)}
                  />

                  <Button
                    size="sm"
                    type="button"
                    disabled={HAS_COOPERATIVE_EMPTY_INPUTS}
                    className="flex items-center justify-center w-24"
                    onClick={() => handleAddEntity(EntityTypes.COOPERATIVE)}
                  >
                    Adicionar
                  </Button>
                </div>
              </div>
              {filteredCooperatives?.length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {filteredCooperatives?.map((entity, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + entity?.id}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-6 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-bold text-sm w-full flex items-center justify-start">
                              {entity?.cooperativeLegalName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {entity?.cooperativeDocumentId}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {entity?.financialAccount?.financialAccountLegalName}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() =>
                                handleRemoveEntity(index, EntityTypes.COOPERATIVE)
                              }
                            />
                          </div>
                        </div>
                        {index !== filteredCooperatives?.length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
        </div>
      </div>
      <ContactGroupsDangerModal
        danger={DANGER_TOAST}
        ModalOverlay={ModalOverlay}
        onClickContinue={onClickContinue}
        onClickCloseModal={onClickCloseModal}
      />
    </>
  );
}
