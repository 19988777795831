import { formatDateToSubmitDateString } from './form';

const LOCALE_OPTIONS = 'pt-BR';

const INVALID_DATE_MESSAGE = 'Invalid Date';

const INCLUDE_DAY = (value: string) =>
  new Date(+value.split('/')[1], +value.split('/')[0] - 1, 1).toLocaleDateString(
    LOCALE_OPTIONS
  );
const ONLY_OBJECTS = (value: string) => typeof value === 'object';
const ONLY_STRINGS = (value: string) => typeof value === 'string';
const DATE_IS_VALID = (date: string) =>
  String(new Date(date)) !== INVALID_DATE_MESSAGE;
const IS_A_DATE = (value: string) =>
  value?.split('/')?.length >= 2 && value?.split('.')?.length < 2;
const IS_A_DECIMAL = (value: string) => value?.split(',')?.length === 2;
const IS_A_BANK_NUMBER = (value: string) =>
  value?.split('-')?.length === 2 && value?.split('-')[1].length === 1;
const IS_A_PHONE_NUMBER = (value: string) => value?.split('(')?.length >= 2;
const IS_A_COMPANY_DOCUMENT = (value: string) =>
  value?.split('.')?.length >= 2 && value?.split('/')?.length >= 2;
const IS_A_INDIVIDUAL_DOCUMENT = (value: string) =>
  value?.split('.')?.length >= 2 && value?.split('-')?.length >= 2;
const IS_A_CURRENCY = (value: string) => value?.split('R$')?.length === 2;
const IS_A_POSTAL_CODE = (value: string) =>
  value?.split('-')?.length === 2 &&
  !IS_A_BANK_NUMBER(value) &&
  !IS_A_PHONE_NUMBER(value) &&
  !IS_A_COMPANY_DOCUMENT(value);
const FORMAT_DATE = (date: string) =>
  String(
    new Date(+date.split('/')[2], +date.split('/')[1] - 1, +date.split('/')[0])
  );
const UNMASK_CURRENCY = (value: string) =>
  value.split('R$')[1].replace(/\./g, '').replace(',', '.').trim();
const UNMASK_DECIMAL = (value: string) =>
  value.replace(/\./g, '').replace(',', '.').trim();
export const ALPHA_NUMERIC = (value: string) => value.replace(/[^a-zA-Z0-9]/g, '');

export function unMaskFormFields(data: any) {
  const unMaskedData = { ...data };

  !!data &&
    Object.keys(data)
      .filter((key) => ONLY_STRINGS(data[key]))
      .forEach((key) => {
        if (IS_A_DATE(data[key])) {
          return (unMaskedData[key] = formatDateToSubmitDateString(
            DATE_IS_VALID(FORMAT_DATE(data[key]))
              ? FORMAT_DATE(data[key])
              : new Date(INCLUDE_DAY(data[key])).toLocaleDateString(LOCALE_OPTIONS)
          ));
        } else if (IS_A_CURRENCY(data[key])) {
          return (unMaskedData[key] = UNMASK_CURRENCY(data[key]));
        } else if (IS_A_DECIMAL(data[key])) {
          return (unMaskedData[key] = UNMASK_DECIMAL(data[key]));
        } else if (
          IS_A_POSTAL_CODE(data[key]) ||
          IS_A_PHONE_NUMBER(data[key]) ||
          IS_A_COMPANY_DOCUMENT(data[key]) ||
          IS_A_INDIVIDUAL_DOCUMENT(data[key])
        ) {
          return (unMaskedData[key] = ALPHA_NUMERIC(data[key]));
        } else if (IS_A_BANK_NUMBER(data[key])) {
          return (unMaskedData[key] = data[key]);
        } else return (unMaskedData[key] = data[key]);
      });

  !!data &&
    Object.keys(data)
      .filter((key) => ONLY_OBJECTS(data[key]))
      .forEach((key) => {
        unMaskedData[key] = unMaskFormFields(data[key]);
      });

  return unMaskedData;
}
