import { gql } from '@apollo/client';

import {
  Contacts,
  Entities,
  ContactGroups,
} from 'contactGroups/models/contactGroups';

export interface ContactGroupsUpdateMutationInput {
  id: number;
  groupName: string;
  entities: Entities;
  contacts: Contacts;
}

export interface ContactGroupsUpdateMutationVariables {
  contactGroupUpdateInput: ContactGroupsUpdateMutationInput;
}

export interface ContactGroupsUpdated {
  contactGroups: ContactGroups;
}

export const CONTACT_GROUP_UPDATE_MUTATION = gql`
  mutation contactGroupUpdate($contactGroupUpdateInput: ContactGroupUpdateInput!) {
    contactGroupUpdate(input: $contactGroupUpdateInput) {
      id
      groupName
      contacts {
        domain {
          id
          contactName
          contactEmail
          contactPhone
        }
        owner {
          id
          contactName
          contactEmail
          contactPhone
        }
        financial {
          id
          contactName
          contactEmail
          contactPhone
        }
        juridical {
          id
          contactName
          contactEmail
          contactPhone
        }
        technical {
          id
          contactName
          contactEmail
          contactPhone
        }
        administrative {
          id
          contactName
          contactEmail
          contactPhone
        }
      }
      entities {
        cooperative {
          id
        }
        consumerUnit {
          id
        }
        generationUnit {
          id
        }
      }
    }
  }
`;
