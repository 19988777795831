import { gql } from '@apollo/client';
import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';

export interface ConsumerUnitUpdateMutationInput {
  id: number;
  consumerUnitAddressCity: string;
  consumerUnitAddressPostalCode: number;
  consumerUnitAddressState: string;
  consumerUnitAddressStreet: string;
  consumerUnitAddressDistrict: string;
  consumerUnitAddressComplement?: string;
  powerDistributionUnitCredentialsUser: string;
  powerDistributionUnitCredentialsPasswordInput?: string;
}

export interface ConsumerUnitUpdateMutationVariables {
  consumerUnitUpdateInput: ConsumerUnitUpdateMutationInput;
}

export interface ConsumerUnitUpdateUpdated {
  consumerUnitUpdate: ConsumerUnit;
}

export const CONSUMER_UNIT_UPDATE_MUTATION = gql`
  mutation consumerUnitUpdate($consumerUnitUpdateInput: consumerUnitUpdateInput!) {
    consumerUnitUpdate(input: $consumerUnitUpdateInput) {
      id
      consumerUnitPowerDistributionUnitIdentifier
      consumerUnitAddressCity
      consumerUnitAddressPostalCode
      consumerUnitAddressState
      consumerUnitAddressStreet
      consumerUnitAddressDistrict
      consumerUnitAddressComplement
      powerDistributionUnitCredentialsUser
      consumerUnitModality
      consumerUnitConsumptionClass
      consumerUnitConsumptionGroupType
      powerDistributionUnitCredentialsUser
      cooperative {
        id
        cooperativeLegalName
      }
      cooperativeMember {
        id
        cooperativeMemberLegalName
      }
      powerDistributionUnit {
        id
        powerDistributionUnitLegalName
      }
    }
  }
`;
