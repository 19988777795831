import { gql } from '@apollo/client';
import { PowerDistributionUnit } from 'powerDistributionUnits/models/powerDistributionUnit';

export interface PowerDistributionUnitCreateMutationInput {
  powerDistributionUnitLegalName: string;
  powerDistributionUnitDocumentId: string;
  powerDistributionUnitCountryState: string;
}

export interface PowerDistributionUnitMutationVariables {
  powerDistributionUnitCreateInput: PowerDistributionUnitCreateMutationInput;
}

export interface PowerDistributionUnitCreated {
  powerDistributionUnitCreate: PowerDistributionUnit;
}

export const POWER_DISTRIBUTION_UNIT_CREATE_MUTATION = gql`
  mutation powerDistributionUnitCreate(
    $powerDistributionUnitCreateInput: PowerDistributionUnitCreateInput!
  ) {
    powerDistributionUnitCreate(input: $powerDistributionUnitCreateInput) {
      id
      powerDistributionUnitCountryState
      powerDistributionUnitDocumentId
      powerDistributionUnitLegalName
    }
  }
`;
