import { gql } from '@apollo/client';
import { CooperativeMember } from 'cooperatives/models/cooperativeMember';

interface CooperativeMemberConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: CooperativeMember[];
}

export interface CooperativeMembersList {
  cooperativeMembers: CooperativeMemberConnection;
}

export const cooperativeMembersTypename = 'CooperativeMemberConnection';

export const COOPERATIVE_MEMBERS_QUERY = gql`
  query cooperativeMembers(
    $after: String
    $before: String
    $pageSize: Int
    $filters: CooperativeMemberFilterOptions
  ) {
    cooperativeMembers(
      before: $before
      after: $after
      pageSize: $pageSize
      filters: $filters
    ) {
      entries {
        id
        cooperativeMemberEntityType
        cooperativeMemberDocumentId
        cooperativeMemberLegalName
        cooperativeMemberModalityType
        cooperative {
          cooperativeLegalName
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
