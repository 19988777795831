import React, { useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import CheckboxField from 'ui/form/CheckboxField';

import { MdChevronLeft, MdSearch } from 'react-icons/md';

import BaseInput, { Props as BaseProps } from './BaseInput';

export interface Option<T> {
  key: T | null;
  value: string;
}

export interface MultipleSearchInputProps<T> {
  isOpen?: boolean;
  disabled?: boolean;
  placeholder?: string;
  options?: Option<T>[];
  isAllSelectable?: boolean;
  selectedOption?: Option<T>[] | null;
  selectItem?: (item: Option<T>) => void;
  value?: string | number | readonly string[];
  selectAll?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function MultipleSearchInput({
  value,
  isOpen,
  options,
  onChange,
  selectAll,
  selectItem,
  placeholder,
  selectedOption,
  isAllSelectable,
  disabled = options?.length === 0,
  ...props
}: MultipleSearchInputProps<string | number> &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement> & BaseProps,
    HTMLInputElement
  >) {
  const [selectedOptionReference, setSelectedOptionReference] = useState({
    value: '',
    counter: 0,
  });

  const [isOptionsOpen, setIsOptionsOpen] = useState(isOpen || false);

  const [optionsList, setOptionsList] = useState<Option<string | number>[]>(
    options || []
  );

  const IS_EMPTY = useMemo(() => {
    return optionsList?.length === 0;
  }, [optionsList]);

  const handleFilter = useMemo(() => {
    if (!optionsList) {
      return [];
    }

    return optionsList.filter(
      (item) =>
        (item.key === null && isAllSelectable) ||
        item.value.toLowerCase().includes(value?.toString().toLowerCase() || '')
    );
  }, [isAllSelectable, optionsList, value]);

  const IS_ALL_SELECTED = useMemo(() => {
    return selectedOption?.length === handleFilter?.length - 1;
  }, [selectedOption?.length, handleFilter?.length]);

  const SHOW_OPTIONS = (!IS_EMPTY && !!value) || (isOptionsOpen && !disabled);

  useEffect(() => {
    isAllSelectable &&
      options &&
      setOptionsList(
        options.length > 1
          ? [{ key: null, value: 'Selecionar todas' }, ...(options || [])]
          : [...(options || [])]
      );
  }, [isAllSelectable, options]);

  const handleSelect = useCallback(
    (item: Option<string | number>) => {
      isAllSelectable && item.key === null ? selectAll?.() : selectItem?.(item);
    },
    [isAllSelectable, selectAll, selectItem]
  );

  const handleCheck = useMemo(
    () => (item: Option<string | number>) => {
      return isAllSelectable && item.key === null
        ? IS_ALL_SELECTED
        : selectedOption?.some((option) => option.key === item.key);
    },
    [selectedOption, isAllSelectable, IS_ALL_SELECTED]
  );

  const handleValue = useMemo(
    () => () => {
      if (selectedOption?.length === 1) {
        return setSelectedOptionReference({
          counter: 0,
          value: selectedOption[0].value,
        });
      }

      return setSelectedOptionReference(
        (selectedOption &&
          selectedOption?.length > 1 && {
            value: selectedOption[0].value,
            counter: selectedOption?.length - 1,
          }) || { value: '', counter: 0 }
      );
    },
    [selectedOption]
  );

  useEffect(() => {
    handleValue();
  }, [handleValue]);

  return (
    <fieldset className="flex flex-col w-full items-center gap-2 relative">
      <div
        className={classNames(
          'flex items-center justify-between w-full border border-gray-dark500 bg-gray-dark600 shadow-none rounded-md px-4',
          {
            'bg-gray-dark700 border-gray-dark700': disabled,
          }
        )}
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center justify-start w-full">
            <MdSearch
              size={25}
              color="#6B6B6B"
              style={{
                width: 'fit-content',
              }}
            />
            <BaseInput
              {...props}
              type="text"
              value={value}
              autoComplete="off"
              onChange={onChange}
              disabled={disabled}
              placeholder={IS_EMPTY ? 'Nenhuma opção disponível' : placeholder}
              className="appearence-none border-none focus:outline-none focus:ring-transparent w-full bg-gray-dark600 shadow-none rounded-md placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark400 text-sm"
            />
          </div>
          {!!selectedOptionReference.value && (
            <div
              className="grid items-center justify-end gap-1 mr-2"
              style={{
                width: '50%',
                gridTemplateColumns:
                  selectedOptionReference.counter > 0 ? '1fr 30px' : '1fr',
              }}
            >
              <span
                className="text-gray-dark500 font-bold text-sm"
                style={{
                  overflow: 'hidden',
                  textAlign: 'right',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  textOverflow: 'ellipsis',
                }}
              >
                {selectedOptionReference.value}
              </span>
              {selectedOptionReference.counter > 0 && (
                <span
                  className="text-gray-dark500 font-bold text-sm w-full"
                  style={{
                    textAlign: 'right',
                    display: 'inline-block',
                  }}
                >
                  + {selectedOptionReference.counter}
                </span>
              )}
            </div>
          )}
        </div>
        <MdChevronLeft
          size={20}
          color="#6B6B6B"
          aria-hidden="true"
          className="cursor-pointer"
          onClick={() => !IS_EMPTY && setIsOptionsOpen(!isOptionsOpen)}
          style={{ transform: `rotate(${isOptionsOpen ? 90 : -90}deg)` }}
        />
      </div>
      {SHOW_OPTIONS && (
        <div className="flex w-full items-start justify-start flex-col border border-gray-dark500 rounded-md bg-gray-dark600 max-h-[308px] overflow-y-auto scrollbar focus:outline-none absolute top-11 z-20">
          {handleFilter?.map((item) => {
            return (
              <div
                key={item.key}
                className={classNames(
                  'flex w-full items-center justify-start bg-gray-dark600 shadow-none placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark500 text-sm hover:text-gray-dark400 text-sm hover:bg-gray-dark550 px-4',
                  {
                    'bg-gray-dark700': disabled,
                    'bg-gray-dark550': handleCheck(item),
                    'border-b border-gray-dark500 hover:border-none':
                      isAllSelectable && item.key === null,
                  }
                )}
              >
                <CheckboxField
                  inputLabel={item.value}
                  checked={handleCheck(item)}
                  onClick={() => handleSelect(item)}
                  wrapperClassName={
                    'flex items-center justify-start w-full cursor-pointer'
                  }
                />
              </div>
            );
          })}
        </div>
      )}
    </fieldset>
  );
}
