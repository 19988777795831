import { useEffect, useState } from 'react';

import { has } from 'lodash';

import { setFormError } from 'utils/form';
import { unMaskFormFields } from 'utils/unmask';

import { useTranslation } from 'react-i18next';

import { useHistory, useParams } from 'react-router';

import { updateCacheById } from 'graphql/apollo/cache';

import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';

import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';

import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { OverlayConfig, OverlayProps } from 'ui/models/overlay';
import { DEFAULT_OVERLAY_CONFIG, ToastProps } from 'ui/contexts/overlay/Toast';

import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import {
  contactGroupTypename,
  CONTACT_GROUP_QUERY,
} from 'contactGroups/graphql/contactGroupQuery';

import {
  ContactGroupDeleteMutationVariables,
  CONTACT_GROUP_DELETE_MUTATION,
} from 'contactGroups/graphql/contactGroupsDeleteMutation';

import {
  ContactGroupsUpdated,
  ContactGroupsUpdateMutationVariables,
  CONTACT_GROUP_UPDATE_MUTATION,
} from 'contactGroups/graphql/contactGroupsUpdateMutation';

import ContactGroupsDeleteButton from 'contactGroups/components/DeleteButton';
import ContactGroupsFormFieldsComponent from 'contactGroups/components/form/ContactGroupsFormFields';

import {
  ContactGroupsFormFields,
  ContactGroupsFormInputs,
  ContactGroupsFormErrors,
} from 'contactGroups/components/form/ContactGroupsFormFields';

import {
  Contacts,
  Entities,
  ContactGroups,
} from 'contactGroups/models/contactGroups';

import {
  CONSUMER_UNITS_QUERY,
  ConsumerUnitsList,
} from 'consumerUnits/graphql/consumerUnitsQuery';

import {
  CooperativesSelectList,
  COOPERATIVES_SELECT_QUERY,
  COOPERATIVES_SELECT_QUERY_VARIABLES,
} from 'cooperatives/graphql/cooperativesSelectQuery';

import {
  GenerationUnitsSelectList,
  GENERATION_UNITS_SELECT_QUERY,
  GENERATION_UNITS_SELECT_QUERY_VARIABLES,
} from 'generationUnits/graphql/generationUnitsSelectQuery';

import {
  COOPERATIVE_MEMBERS_SELECT_QUERY,
  COOPERATIVE_MEMBERS_SELECT_QUERY_VARIABLES,
  CooperativeMembersSelectList,
} from 'cooperatives/graphql/cooperativeMembersSelectQuery';

import { CONSUMER_UNITS_SELECT_QUERY_VARIABLES } from 'consumerUnits/graphql/consumerUnitsSelectQuery';

import { ContactGroups as ContactGroupsData } from '__factory__/contactGroups';

const DASHBOARD_CONTACT_GROUPS_ROUTE = '/dashboard/contact-groups';

const BREADCRUMB_PAGES: Page[] = [
  {
    current: false,
    name: 'Grupo & Contatos',
    route: DASHBOARD_CONTACT_GROUPS_ROUTE,
  },
  {
    route: null,
    current: true,
    name: 'Edição de Grupo & Contatos',
  },
];

const TITLE = 'Edição de Grupo & Contatos';

const DELETE_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Houve um erro ao tentar excluir Grupo & Contatos',
};

const DELETE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso',
  variant: 'primary',
  text: 'Grupo & Contatos excluídos com sucesso',
};

const UPDATE_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Houve um erro ao tentar atualizar Grupo & Contatos',
};

const UPDATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao atualizar Grupo & Contatos.',
};

const FETCH_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar Grupo & Contatos',
};

export default function EditContactGroupsPage() {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { id } = useParams<{ id: string }>();
  const { ConfirmOverlay, showConfirm, closeConfirm } = useConfirm();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [contactList, setContactList] = useState<Contacts>({
    owner: [],
    domain: [],
    financial: [],
    juridical: [],
    technical: [],
    administrative: [],
  });

  const [entitiesList, setEntitiesList] = useState<Entities>({
    cooperative: [],
    consumerUnit: [],
    generationUnit: [],
  });

  const {
    reset,
    control,
    setValue,
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactGroupsFormInputs>();

  const [
    consumerUnitsSelect,
    { data: consumerUnitsSelectData, loading: consumerUnitsSelectLoading },
  ] = useLazyQuery<ConsumerUnitsList>(
    CONSUMER_UNITS_QUERY,
    CONSUMER_UNITS_SELECT_QUERY_VARIABLES
  );

  const [
    cooperativesSelect,
    { data: cooperativesSelectData, loading: cooperativesSelectLoading },
  ] = useLazyQuery<CooperativesSelectList>(
    COOPERATIVES_SELECT_QUERY,
    COOPERATIVES_SELECT_QUERY_VARIABLES
  );

  const [
    generationUnitsSelect,
    { data: generationUnitsSelectData, loading: generationUnitsSelectLoading },
  ] = useLazyQuery<GenerationUnitsSelectList>(
    GENERATION_UNITS_SELECT_QUERY,
    GENERATION_UNITS_SELECT_QUERY_VARIABLES
  );

  const [
    cooperativeMembersSelect,
    { data: cooperativeMembersSelectData, loading: cooperativeMembersSelectLoading },
  ] = useLazyQuery<CooperativeMembersSelectList>(
    COOPERATIVE_MEMBERS_SELECT_QUERY,
    COOPERATIVE_MEMBERS_SELECT_QUERY_VARIABLES
  );

  useEffect(() => {
    consumerUnitsSelect();
  }, [consumerUnitsSelect]);

  useEffect(() => {
    cooperativesSelect();
  }, [cooperativesSelect]);

  useEffect(() => {
    generationUnitsSelect();
  }, [generationUnitsSelect]);

  useEffect(() => {
    cooperativeMembersSelect();
  }, [cooperativeMembersSelect]);

  const [contactGroupUpdateMutation, { loading: updateLoading }] = useMutation<
    ContactGroupsUpdated,
    ContactGroupsUpdateMutationVariables
  >(CONTACT_GROUP_UPDATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'message')) {
        addToast(UPDATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof ContactGroupsFormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(UPDATE_SUCCESS_TOAST);
      push(DASHBOARD_CONTACT_GROUPS_ROUTE);
    },
  });

  const [contactGroupDeleteMutation, { loading: deleteLoading }] =
    useMutation<ContactGroupDeleteMutationVariables>(CONTACT_GROUP_DELETE_MUTATION, {
      onError() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(DELETE_ERROR_TOAST);
      },
      onCompleted() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(DELETE_SUCCESS_TOAST);
        push(DASHBOARD_CONTACT_GROUPS_ROUTE);
      },
    });

  const [
    contactGroupQuery,
    {
      data: contactGroupData = ContactGroupsData(1),
      error: fetchContactGroupError,
      loading: fetchContactGroupLoading,
    },
  ] = useLazyQuery<ContactGroups>(CONTACT_GROUP_QUERY, {
    variables: {
      id: id,
    },
  });

  useEffect(() => {
    contactGroupQuery();
  }, [contactGroupQuery, id]);

  useEffect(() => {
    if (fetchContactGroupError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchContactGroupError]);

  useEffect(() => {
    if (!!contactGroupData) {
      setValue('groupName', contactGroupData.groups.groupName);

      setValue('contacts.owner', contactGroupData.contacts.owner);
      setValue('contacts.domain', contactGroupData.contacts.domain);
      setValue('contacts.technical', contactGroupData.contacts.technical);
      setValue('contacts.financial', contactGroupData.contacts.financial);
      setValue('contacts.juridical', contactGroupData.contacts.juridical);
      setValue('contacts.administrative', contactGroupData.contacts.administrative);

      setValue(
        'domainContactEmail',
        contactGroupData.contacts.domain[0].contactEmail
      );

      setValue('entities.cooperative', contactGroupData.entities.cooperative);
      setValue('entities.consumerUnit', contactGroupData.entities.consumerUnit);
      setValue('entities.generationUnit', contactGroupData.entities.generationUnit);
    }
  }, [contactGroupData, setValue]);

  const [confirmOverlayProps, setConfirmOverlayProps] = useState<OverlayProps>(
    DEFAULT_OVERLAY_CONFIG
  );

  const isLoading = !!(
    deleteLoading ||
    updateLoading ||
    fetchContactGroupLoading ||
    cooperativesSelectLoading ||
    consumerUnitsSelectLoading ||
    generationUnitsSelectLoading ||
    cooperativeMembersSelectLoading
  );

  useEffect(() => {
    if (isLoading) {
      return showLoading();
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onSubmit: SubmitHandler<ContactGroupsFormFields> = (
    contactGroupUpdateInput
  ) => {
    contactGroupUpdateInput = Object.assign(contactGroupUpdateInput, {
      contacts: contactList,
      entities: entitiesList,
    });

    contactGroupUpdateInput = unMaskFormFields(contactGroupUpdateInput);

    const hasLeastOneEntity =
      entitiesList?.cooperative?.length ||
      entitiesList?.consumerUnit?.length ||
      entitiesList?.generationUnit?.length;

    const hasLeastOneDomainContact = contactList?.domain?.length;

    if (!hasLeastOneDomainContact) {
      setError('contacts', {
        type: 'manual',
        message: 'Este campo precisa ser preenchido',
      });
    }

    if (!hasLeastOneEntity) {
      setError('entities', {
        type: 'manual',
        message: 'Você precisa vincular pelo menos uma entidade ao grupo',
      });
    }

    setTimeout(() => clearErrors(), 3000);

    if (hasLeastOneEntity && hasLeastOneDomainContact) {
      contactGroupUpdateMutation({
        variables: {
          contactGroupUpdateInput: {
            id: Number(id),
            contacts: contactGroupUpdateInput.contacts,
            entities: contactGroupUpdateInput.entities,
            groupName: contactGroupUpdateInput.groupName,
          },
        },
      });
    }
  };

  const onConfirmDelete = () =>
    contactGroupDeleteMutation({
      variables: { id: id },
      update(cache) {
        updateCacheById(cache, Number(id), contactGroupTypename);
      },
    });

  const onClickDelete = (overlayConfig: OverlayConfig) => {
    setConfirmOverlayProps({
      ...overlayConfig,
      onConfirm: onConfirmDelete,
    });

    showConfirm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm title={TITLE} breadcrumbPages={BREADCRUMB_PAGES}>
            {!!contactGroupData && (
              <>
                <ContactGroupsDeleteButton
                  disabled={isLoading}
                  onClick={onClickDelete}
                />
                <Button type="submit" size="sm" disabled={isLoading}>
                  Salvar
                </Button>
              </>
            )}
            <ConfirmOverlay {...confirmOverlayProps} onCancel={closeConfirm} />
          </DashboardMainHeaderForm>
        }
      >
        {!!contactGroupData && (
          <ContactGroupsFormFieldsComponent
            reset={reset}
            control={control}
            isUpdatable={true}
            register={register}
            setValue={setValue}
            formErrors={errors as ContactGroupsFormErrors}
            cooperatives={cooperativesSelectData?.cooperatives?.entries || []}
            consumerUnits={consumerUnitsSelectData?.consumerUnits?.entries || []}
            generationUnits={
              generationUnitsSelectData?.generationUnits?.entries || []
            }
            cooperativeMembers={
              cooperativeMembersSelectData?.cooperativeMembers?.entries || []
            }
            handleGetEntityList={(entities: Entities) => setEntitiesList(entities)}
            handleGetContactList={(contacts: Contacts) => setContactList(contacts)}
          />
        )}
      </Dashboard>
    </form>
  );
}
