import { gql } from '@apollo/client';

interface PowerDistributionUnitsSelectConnection {
  entries: {
    id: number;
    powerDistributionUnitLegalName: string;
  }[];
}

export interface PowerDistributionUnitsSelectList {
  powerDistributionUnits: PowerDistributionUnitsSelectConnection;
}

export const powerDistributionUnitTypename = 'PowerDistributionUnitConnection';

const MAX_PAGE_SIZE = 100;

export const POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const POWER_DISTRIBUTION_UNITS_SELECT_QUERY = gql`
  query powerDistributionUnits($after: String, $before: String, $pageSize: Int) {
    powerDistributionUnits(after: $after, before: $before, pageSize: $pageSize) {
      entries {
        id
        powerDistributionUnitLegalName
      }
    }
  }
`;
