import { gql } from '@apollo/client';

import { ConsumerUnitBillingCycle } from 'consumerUnits/consumerUnitBillingCycles/models/consumerUnitBillingCycles';

interface ConsumerUnitBillingCycleConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: ConsumerUnitBillingCycle[];
}

export interface ConsumerUnitBillingCycleList {
  consumerUnitBillingCycles: ConsumerUnitBillingCycleConnection;
}

export const consumerUnitBillingCyclesTypename =
  'ConsumerUnitBillingCycleConnection';

const MAX_PAGE_SIZE = 100;

export const CONSUMER_UNIT_BILLING_CYCLES_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const CONSUMER_UNIT_BILLING_CYCLES_QUERY = gql`
  query consumerUnitBillingCycles(
    $after: String
    $before: String
    $pageSize: Int
    $filters: ConsumerUnitBillingCycleFilterOptions
  ) {
    consumerUnitBillingCycles(
      after: $after
      before: $before
      pageSize: $pageSize
      filters: $filters
    ) {
      afterCursor
      beforeCursor
      entries {
        id
        consumerUnitBillingCycleDate
        consumerUnitBillingCapture {
          id
          consumerUnitBillingCaptureUrl
          consumerUnitBillingCaptureError
          consumerUnitBillingCaptureStatus
          consumerUnitBillingCaptureReferenceMonth
          consumerUnitBillingCaptureConsumerUnitType
          consumerUnitPowerDistributionUnitIdentifier
        }
        consumerUnit {
          id
          consumerUnitModality
          consumerUnitAddressCity
          consumerUnitAddressState
          consumerUnitAddressStreet
          consumerUnitAddressDistrict
          consumerUnitConsumptionClass
          consumerUnitAddressPostalCode
          consumerUnitAddressComplement
          consumerUnitConsumptionGroupType
          powerDistributionUnitCredentialsUser
          consumerUnitPowerDistributionUnitIdentifier
          cooperativeMember {
            id
            cooperativeMemberLegalName
            cooperativeMemberEntityType
            cooperativeMemberDocumentId
          }
          powerDistributionUnit {
            id
            powerDistributionUnitCountryState
            powerDistributionUnitDocumentId
            powerDistributionUnitLegalName
            powerDistributionUnitDeletedAt
          }
        }
        powerDistributionUnitBillData {
          id
          billDueDate
          billIssueDate
          billTotalValue
          billReferenceDate
          billConsumerUnitId
          billNextReadingDate
          billConsumerUnitType
          billReadingStartedAt
          billTotalReadingDays
          billReadingFinishedAt
          powerDistributionUnitId
          billConsumerUnitConsumptionGroupType
          billExcessEnergyCredits {
            excessEnergyCreditsTotal
            excessEnergyCreditsOnPeak
            excessEnergyCreditsOffPeak
          }
          billGeneratedEnergyCredits {
            generatedEnergyCreditsTotal
            generatedEnergyCreditsOnPeak
            generatedEnergyCreditsOffPeak
          }
          billConsumedEnergyCredits {
            consumedEnergyCreditsTotal
            consumedEnergyCreditsOnPeak
            consumedEnergyCreditsOffPeak
          }
          billConsumedEnergyCreditsTariffs {
            consumedEnergyCreditsTariffsTotal
            consumedEnergyCreditsTariffsOnPeak
            consumedEnergyCreditsTariffsOffPeak
          }
          billConsumedEnergyKwh {
            consumedEnergyKwhTotal
            consumedEnergyKwhOnPeak
            consumedEnergyKwhOffPeak
          }
        }
        economyReport {
          flagEconomy
          economyValue
          energyEconomy
          economyReportUrl
          economyReferenceDate
          economyReportExternalId
          economyValueMonthlyPercentage
        }
        summarizedCreditWallet {
          usedCredits
          receivedCredits
          accumulatedCredits
        }
        economyValuesAveragePercentage {
          economyValueSum
          economyValuePercentage
        }
      }
    }
  }
`;
