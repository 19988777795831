import { FormContainer } from 'ui';
import omit from 'lodash/omit';
import TextField from 'ui/form/TextField';
import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';

export type FormFields = {
  powerDistributionUnitLegalName: string;
  powerDistributionUnitDocumentId: string;
  powerDistributionUnitCountryState: string;
};

type Field =
  | 'powerDistributionUnitLegalName'
  | 'powerDistributionUnitCountryState'
  | 'powerDistributionUnitDocumentId';

export const FORM_FIELDS: Field[] = [
  'powerDistributionUnitLegalName',
  'powerDistributionUnitCountryState',
  'powerDistributionUnitDocumentId',
];

export type FormErrors = {
  powerDistributionUnitLegalName?: FieldError | undefined;
  powerDistributionUnitDocumentId?: FieldError | undefined;
  powerDistributionUnitCountryState?: FieldError | undefined;
};

export default function PowerDistributionUnitFields({
  errors,
  register,
  control,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  control: Control<FormFields>;
}) {
  return (
    <FormContainer>
      <TextField
        required
        error={errors.powerDistributionUnitLegalName?.message}
        label="Nome"
        id="powerDistributionUnitLegalName"
        {...register('powerDistributionUnitLegalName', {
          required: 'Campo obrigatório',
        })}
        placeholder="Ex.: COPEL"
      />

      <Controller
        name="powerDistributionUnitCountryState"
        control={control}
        rules={{ required: 'Campo obrigatório' }}
        render={({ field }) => (
          <CountryStateSelectField
            required
            {...omit(field, 'ref')}
            error={errors.powerDistributionUnitCountryState?.message}
            label="Estado"
            id="powerDistributionUnitCountryState"
          />
        )}
      />

      <TextField
        required
        error={errors.powerDistributionUnitDocumentId?.message}
        label="Documento"
        id="powerDistributionUnitDocumentId"
        {...register('powerDistributionUnitDocumentId', {
          required: 'Campo obrigatório',
        })}
        placeholder="Ex.: 28.054.303/0001-06"
      />
    </FormContainer>
  );
}
