import { Button } from 'ui';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import useToastContext from 'ui/hooks/useToast';
import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';
import UserFormFields, {
  FormFields,
  FORM_FIELDS,
} from 'internalUsers/components/form/UserFormFields';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  InternalUserUpdated,
  InternalUserUpdateMutationVariables,
  INTERNAL_USER_UPDATE_MUTATION,
} from 'internalUsers/graphql/internalUserUpdateMutation';
import { setFormError } from 'utils/form';
import { INTERNAL_USER_QUERY } from 'internalUsers/graphql/internalUserQuery';
import { DEFAULT_OVERLAY_CONFIG, ToastProps } from 'ui/contexts/overlay/Toast';
import {
  InternalUserToggleAccessStatusVariables,
  INTERNAL_USER_TOGGLE_ACCESS_STATUS_MUTATION,
} from 'internalUsers/graphql/internalUserToggleAccessStatusMutation';
import {
  InternalUserDeleteMutationVariables,
  INTERNAL_USER_DELETE_MUTATION,
} from 'internalUsers/graphql/internalUserDeleteMutation';
import {
  InternalUserResetPasswordInstructionsVariables,
  INTERNAL_USER_RESET_PASSWORD_INSTRUCTIONS_MUTATION,
} from 'internalUsers/graphql/internalUserResetPasswordInstructionsMutation';
import { ToggleAccessButton } from 'internalUsers/components/ToggleAccessButton';
import { OverlayConfig, OverlayProps } from 'ui/models/overlay';
import DeleteButton from 'internalUsers/components/DeleteButton';
import ResetPasswordButton from 'internalUsers/components/ResetPasswordButton';
import { has } from 'lodash';

const DASHBOARD_INTERNAL_USERS_ROUTE = '/dashboard/internal-users';

const BREADCRUMB_PAGES: Page[] = [
  { name: 'Usuário interno', route: DASHBOARD_INTERNAL_USERS_ROUTE, current: false },
  {
    name: 'Edição de usuário interno',
    route: null,
    current: true,
  },
];

const TITLE = 'Edição de usuário interno';

const UPDATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar atualizar o usuário',
  title: 'Algo deu errado!',
  variant: 'danger',
};
const UPDATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao atualizar o Usuário.',
};
const FETCH_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar o Usuário',
};

export default function EditInternalUsersPage() {
  const { ConfirmOverlay, showConfirm, closeConfirm } = useConfirm();
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();

  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [internalUserUpdateMutation, { loading: updateLoading }] = useMutation<
    InternalUserUpdated,
    InternalUserUpdateMutationVariables
  >(INTERNAL_USER_UPDATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(UPDATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(UPDATE_SUCCESS_TOAST);
      push(DASHBOARD_INTERNAL_USERS_ROUTE);
    },
  });

  const [internalUserToggleAccessMutation, { loading: toggleAccessLoading }] =
    useMutation<InternalUserToggleAccessStatusVariables>(
      INTERNAL_USER_TOGGLE_ACCESS_STATUS_MUTATION,
      {
        onError() {
          closeConfirm();
          setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
          addToast(UPDATE_ERROR_TOAST);
        },
        onCompleted() {
          closeConfirm();
          setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
          addToast(UPDATE_SUCCESS_TOAST);
        },
      }
    );

  const [internalUserDeleteMutation, { loading: deleteLoading }] =
    useMutation<InternalUserDeleteMutationVariables>(INTERNAL_USER_DELETE_MUTATION, {
      onError() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(UPDATE_ERROR_TOAST);
      },
      onCompleted() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(UPDATE_SUCCESS_TOAST);
        push(DASHBOARD_INTERNAL_USERS_ROUTE);
      },
    });

  const [
    internalUserResetPasswordInstructionsMutation,
    { loading: resetPasswordLoading },
  ] = useMutation<InternalUserResetPasswordInstructionsVariables>(
    INTERNAL_USER_RESET_PASSWORD_INSTRUCTIONS_MUTATION,
    {
      onError() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(UPDATE_ERROR_TOAST);
      },
      onCompleted() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(UPDATE_SUCCESS_TOAST);
      },
    }
  );

  const [
    internalUserQuery,
    { data, loading: fetchUserLoading, error: fetchUserError },
  ] = useLazyQuery(INTERNAL_USER_QUERY, {
    variables: {
      userId: id,
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
  } = useForm<FormFields>();

  useEffect(() => {
    if (fetchUserError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchUserError]);

  useEffect(() => {
    if (data?.internalUser) {
      FORM_FIELDS.forEach((field) => setValue(field, data?.internalUser[field]));
    }
  }, [data, setValue]);

  useEffect(() => {
    internalUserQuery();
  }, [internalUserQuery, id]);

  const [confirmOverlayProps, setConfirmOverlayProps] = useState<OverlayProps>(
    DEFAULT_OVERLAY_CONFIG
  );

  const isLoading = !!(
    resetPasswordLoading ||
    deleteLoading ||
    toggleAccessLoading ||
    updateLoading ||
    fetchUserLoading
  );

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onSubmit: SubmitHandler<FormFields> = (internalUserUpdateInput) => {
    internalUserUpdateMutation({
      variables: {
        internalUserUpdateInput: {
          id: Number(id),
          userName: internalUserUpdateInput.userName,
          userIsAdmin: internalUserUpdateInput.userIsAdmin,
        },
      },
    });
  };

  const onConfirmToggleAccess = () =>
    internalUserToggleAccessMutation({
      variables: { id: Number(id) },
    });

  const onClickToggleAccess = (overlayConfig: OverlayConfig) => {
    setConfirmOverlayProps({
      ...overlayConfig,
      onConfirm: onConfirmToggleAccess,
    });

    showConfirm();
  };

  const onConfirmResetPassword = () =>
    internalUserResetPasswordInstructionsMutation({
      variables: { userEmail: data?.internalUser?.userEmail },
    });

  const onClickResetPassword = (overlayConfig: OverlayConfig) => {
    setConfirmOverlayProps({
      ...overlayConfig,
      onConfirm: onConfirmResetPassword,
    });

    showConfirm();
  };

  const onConfirmDelete = () =>
    internalUserDeleteMutation({
      variables: { id: Number(id) },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: 'InternalUser' });

        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });

  const onClickDelete = (overlayConfig: OverlayConfig) => {
    setConfirmOverlayProps({
      ...overlayConfig,
      onConfirm: onConfirmDelete,
    });

    showConfirm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm title={TITLE} breadcrumbPages={BREADCRUMB_PAGES}>
            {data?.internalUser && (
              <>
                {' '}
                <ResetPasswordButton
                  disabled={isLoading}
                  onClick={onClickResetPassword}
                />
                <ToggleAccessButton
                  onClickToggleAccess={onClickToggleAccess}
                  userAccessBlockedAt={data?.internalUser?.userAccessBlockedAt}
                  disabled={isLoading}
                />
                <DeleteButton onClick={onClickDelete} disabled={isLoading} />
                <Button type="submit" size="sm" disabled={isLoading}>
                  Salvar
                </Button>
              </>
            )}
            <ConfirmOverlay {...confirmOverlayProps} onCancel={closeConfirm} />
          </DashboardMainHeaderForm>
        }
      >
        {data?.internalUser && (
          <UserFormFields
            errors={errors}
            register={register}
            disable={{ userEmail: true }}
          />
        )}
      </Dashboard>
    </form>
  );
}
