import {
  split,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { createLink } from 'apollo-absinthe-upload-link';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

export type EmptyMessage = {
  message: string;
};

type Token = string | null;
type Push = (location: string) => void;
type Logout = (push: Push) => void;

const authLink = (token: Token) => {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
};

export const httpLink = createLink({
  uri: process.env.REACT_APP_URI_GRAPHQL,
});

const wsLink = (authToken: Token) =>
  new WebSocketLink({
    uri: `${process.env.REACT_APP_URI_SOCKET}`,
    options: {
      reconnect: true,
      connectionParams: { authToken },
    },
  });

const splitLink = (token: Token, logout: Logout, push: Push) => {
  const link = logoutLink(logout, push).concat(authLink(token).concat(httpLink));

  if (token) {
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink(token),
      link
    );
  }

  return link;
};

const logoutLink = (logout: Logout, push: Push) =>
  onError((err) => {
    if (err.graphQLErrors && err.graphQLErrors[0]?.message === 'Unauthorized') {
      logout(push);
    }
  });

const client = (token: Token, logout: Logout, push: Push) =>
  new ApolloClient<NormalizedCacheObject>({
    link: splitLink(token, logout, push),
    cache: new InMemoryCache(),
  });

export default client;
