import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';
import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';
import ConsumerUnitsItem from './ConsumerUnitsTableItem';

type Props = {
  consumerUnits: ConsumerUnit[];
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};

export default function ConsumersTable({
  consumerUnits,
  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>UC</TableHeadColumn>
        <TableHeadColumn>Cooperado</TableHeadColumn>
        <TableHeadColumn>Cooperativa</TableHeadColumn>
        <TableHeadColumn>Geradora</TableHeadColumn>
        <TableHeadColumn>Conc.</TableHeadColumn>
        <TableHeadColumn className="text-center">UF</TableHeadColumn>
        <TableHeadColumn className="text-center">Grupo</TableHeadColumn>
        <TableHeadColumn>%Rateio</TableHeadColumn>
        <TableHeadColumn className="text-right">Ações</TableHeadColumn>
      </TableHead>
      <TableBody>
        {consumerUnits.map((consumerUnit: ConsumerUnit) => (
          <ConsumerUnitsItem
            key={consumerUnit.id}
            consumerUnit={consumerUnit}
            onClickEditButton={onClickEditButton}
            onClickRemoveButton={onClickRemoveButton}
            disableEditButton={disableEditButton}
            disableRemoveButton={disableRemoveButton}
          />
        ))}
      </TableBody>
    </Table>
  );
}
