import {
  ConsumerUnit as ConsumerUnitModel,
  ConsumerUnitConsumptionClassType,
  ConsumerUnitModalityType,
  ConsumptionGroupsType,
} from 'consumerUnits/model/consumerUnit';

export const ConsumerUnit = (
  id = new Date().valueOf(),
  consumptionGroupsType = ConsumptionGroupsType.A,
  consumerUnitConsumptionClassType = ConsumerUnitConsumptionClassType.COMMERCIAL,
  consumerUnitModality = ConsumerUnitModalityType.BLUE
): ConsumerUnitModel => ({
  id,
  consumerUnitAddressState: 'PR',
  consumerUnitConsumptionGroupType: consumptionGroupsType,
  consumerUnitPowerDistributionUnitIdentifier: `${id}`,
  consumerUnitAddressCity: `City ${id}`,
  consumerUnitAddressComplement: null,
  consumerUnitAddressDistrict: `District ${id}`,
  consumerUnitAddressPostalCode: id,
  consumerUnitAddressStreet: `Street ${id}`,
  consumerUnitConsumptionClass: consumerUnitConsumptionClassType,
  consumerUnitModality: consumerUnitModality,
  cooperativeId: id,
  cooperativeMemberId: id + 1,
  powerDistributionUnitId: id + 2,
  powerDistributionUnitCredentialsUser: 'user',
  powerDistributionUnitCredentialsPasswordInput: 'password',
  cooperative: {
    id,
    cooperativeLegalName: `Cooperative legal name ${id}`,
  },
  cooperativeMember: {
    id,
    cooperativeMemberLegalName: `Cooperative member legal name ${id}`,
    cooperativeMemberDocumentId: `Cooperative member document ${id}`,
  },
  powerDistributionUnit: {
    id,
    powerDistributionUnitLegalName: `Power distribution unit legal name ${id}`,
  },
  apportionmentCycleConsumerUnit: {
    id,
    apportionmentCycleConsumerUnitPercentual: id * 12,
    generationUnit: {
      id,
      generationUnitLegalName: `Generation unit legal name ${id}`,
    },
  },
});

export default ConsumerUnit;
