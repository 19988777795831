import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';
import { Cooperative } from 'cooperatives/models/cooperative';
import { GenerationUnit } from 'generationUnits/models/generationUnit';

export type GroupId = number;
export type EntityId = number;
export type ContactId = number;
export type ContactGroupId = number;

export enum ContactGroupsExportType {
  SUMMEDUP = 'SUMMEDUP',
  DETAILED = 'DETAILED',
}

export enum ContactTypes {
  OWNER = 'OWNER',
  DOMAIN = 'DOMAIN',
  FINANCIAL = 'FINANCIAL',
  TECHNICAL = 'TECHNICAL',
  JURIDICAL = 'JURIDICAL',
  ADMINISTRATIVE = 'ADMINISTRATIVE',
}

export enum EntityTypes {
  COOPERATIVE = 'cooperative',
  CONSUMER_UNIT = 'consumerUnit',
  GENERATION_UNIT = 'generationUnit',
  COOPERATIVE_MEMBER = 'cooperativeMember',
}

export interface Groups {
  id: GroupId;
  groupName: string;
}

export interface Contact {
  id: ContactId;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
}

export interface Contacts {
  owner: Contact[];
  domain: Contact[];
  financial: Contact[];
  technical: Contact[];
  juridical: Contact[];
  administrative: Contact[];
}

export interface Entities {
  cooperative: Cooperative[];
  consumerUnit: ConsumerUnit[];
  generationUnit: GenerationUnit[];
}

export interface ContactGroups {
  groups: Groups;
  entities: Entities;
  id: ContactGroupId;
  contacts: Contacts;
}
