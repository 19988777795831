import * as React from 'react';
import { PowerDistributionUnit } from 'powerDistributionUnits/models/powerDistributionUnit';
import PowerDistributionUnitsTableItem from './PowerDistributionUnitsTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  powerDistributionUnits: PowerDistributionUnit[];
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function PowerDistributionUnitsTable({
  powerDistributionUnits,
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  return (
    <>
      <Table>
        <TableHead>
          <TableHeadColumn>Nome</TableHeadColumn>
          <TableHeadColumn>Documento</TableHeadColumn>
          <TableHeadColumn>UF</TableHeadColumn>
          <TableHeadColumn className="text-right">Ações</TableHeadColumn>
        </TableHead>
        <TableBody>
          {powerDistributionUnits.map(
            (powerDistributionUnit: PowerDistributionUnit) => (
              <PowerDistributionUnitsTableItem
                key={powerDistributionUnit.id}
                powerDistributionUnit={powerDistributionUnit}
                onClickEditButton={onClickEditButton}
                onClickRemoveButton={onClickRemoveButton}
              />
            )
          )}
        </TableBody>
      </Table>
    </>
  );
}
