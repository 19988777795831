import {
  ConsumerUnit,
  ConsumptionGroupsType,
} from 'consumerUnits/model/consumerUnit';

import { CooperativeMember } from 'cooperatives/models/cooperativeMember';
import { ConsumerUnitBillingCapture } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';
import { ConsumerUnitBillingCycle } from 'consumerUnits/consumerUnitBillingCycles/models/consumerUnitBillingCycles';

export type BillConsumerUnitId = number;
export type ConsumerUnitBillDataId = number;
export type PowerDistributionUnitId = number;

export enum ConsumerUnitType {
  CONSUMER = 'CONSUMER',
  GENERATION = 'GENERATION',
}

export type BillConsumedEnergyKwh = {
  consumedEnergyKwhTotal: string;
  consumedEnergyKwhOnPeak: string;
  consumedEnergyKwhOffPeak: string;
};

export type BillExcessEnergyCredits = {
  excessEnergyCreditsTotal: string;
  excessEnergyCreditsOnPeak: string;
  excessEnergyCreditsOffPeak: string;
};

export type BillConsumedEnergyCredits = {
  consumedEnergyCreditsTotal: string;
  consumedEnergyCreditsOnPeak: string;
  consumedEnergyCreditsOffPeak: string;
};

export type BillGeneratedEnergyCredits = {
  generatedEnergyCreditsTotal: string;
  generatedEnergyCreditsOnPeak: string;
  generatedEnergyCreditsOffPeak: string;
};

export type BillConsumedEnergyCreditsTariffs = {
  consumedEnergyCreditsTariffsTotal: string;
  consumedEnergyCreditsTariffsOnPeak: string;
  consumedEnergyCreditsTariffsOffPeak: string;
};

export interface ConsumerUnitBillData {
  id: ConsumerUnitBillDataId;
  billConsumerUnitId: BillConsumerUnitId;
  powerDistributionUnitId: PowerDistributionUnitId;
  billDueDate: string;
  billIssueDate: string;
  billReferenceDate: string;
  billNextReadingDate: string;
  billReadingStartedAt: string;
  billReadingFinishedAt: string;
  billTotalValue: string;
  billTotalReadingDays: string;
  billConsumedEnergyKwh: BillConsumedEnergyKwh;
  billExcessEnergyCredits: BillExcessEnergyCredits;
  billConsumedEnergyCredits: BillConsumedEnergyCredits;
  billGeneratedEnergyCredits: BillGeneratedEnergyCredits;
  billConsumedEnergyCreditsTariffs: BillConsumedEnergyCreditsTariffs;
  billConsumerUnitType: ConsumerUnitType;
  billConsumerUnitConsumptionGroupType: ConsumptionGroupsType;
  consumerUnit?: ConsumerUnit;
  cooperativeMember?: CooperativeMember;
}

export type ConsumerUnitBillDataDetails = {
  consumerUnit: ConsumerUnit;
  consumerUnitBillCycle: ConsumerUnitBillingCycle;
  powerDistributionUnitBillData: ConsumerUnitBillData;
  consumerUnitBillingCapture: ConsumerUnitBillingCapture;
};
