import React from 'react';
import classNames from 'classnames';
import { MaskInterface } from 'utils/masks';
import { FieldError } from 'react-hook-form';
import { handleOnChange, handleValidateMaskOnBlur } from 'utils/validations';

export type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & {
    mask?: MaskInterface;
    error?: string | FieldError;
    ref?: React.Ref<HTMLInputElement>;
    tooltipReference?: React.Ref<HTMLInputElement>;
  },
  HTMLInputElement
>;

export const BaseInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      mask,
      name,
      type,
      error,
      disabled,
      className,
      minLength,
      maxLength,
      autoComplete,
      tooltipReference,
      ...props
    },
    ref
  ) => {
    const [inputError, setInputError] = React.useState<
      string | FieldError | undefined
    >(error);

    return (
      <div ref={tooltipReference} className="relative w-full">
        <input
          {...props}
          ref={ref}
          name={name}
          disabled={disabled}
          type={type || 'text'}
          minLength={minLength}
          maxLength={maxLength}
          autoComplete={autoComplete}
          className={classNames(
            `appearence-none focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-dark500 bg-gray-dark600 shadow-none rounded-md hover:border-primary placeholder-gray-dark500 text-gray-dark400 ${className}`,
            {
              'appearence-none border-gray-dark600 bg-gray-dark700 block text-gray-dark550 cursor-default shadow-none rounded-md hover:border-transparent':
                disabled,
              'appearence-none focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-red-500 bg-transparent shadow-none rounded-md hover:border-red-500 placeholder-red500 focus-within:text-red-500 text-red-500':
                !!error || !!inputError,
            }
          )}
          onChange={(e) => handleOnChange(e, props, setInputError, mask)}
          onBlur={(e) => handleValidateMaskOnBlur(e, props, setInputError, mask)}
        />
        {(error || inputError) && (
          <p className="text-red-500 text-xs mt-3 font-normal text-left absolute">
            {error || inputError}
          </p>
        )}
      </div>
    );
  }
);

export default BaseInput;
