import { gql } from '@apollo/client';

export enum GenerationUnitEnergySourceTypes {
  UTE = 'UTE',
  EOL = 'EOL',
  UFV = 'UFV',
  CGH = 'CGH',
}

interface GenerationUnitsSelectConnection {
  entries: {
    id: number;
    generationUnitLegalName: string;
    generationUnitEnergySource: GenerationUnitEnergySourceTypes;
    financialAccount: {
      id: number;
      financialAccountLegalName: string;
    };
    powerDistributionUnit: {
      id: number;
      powerDistributionUnitLegalName: string;
    };
  }[];
}

export interface GenerationUnitsSelectList {
  generationUnits: GenerationUnitsSelectConnection;
}

export const generationUnitTypename = 'GenerationUnitConnection';

const MAX_PAGE_SIZE = 100;

export const GENERATION_UNITS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const GENERATION_UNITS_SELECT_QUERY = gql`
  query generationUnits($after: String, $before: String, $pageSize: Int) {
    generationUnits(after: $after, before: $before, pageSize: $pageSize) {
      entries {
        id
        generationUnitLegalName
        generationUnitEnergySource
        financialAccount {
          id
          financialAccountLegalName
        }
        powerDistributionUnit {
          id
          powerDistributionUnitLegalName
        }
      }
    }
  }
`;
