import React, { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { MdCheck, MdChevronLeft, MdSearch } from 'react-icons/md';

import BaseInput, { Props as BaseProps } from './BaseInput';

export interface Option<T> {
  key: T | null;
  value: string;
}

export interface SearchInputProps<T> {
  isOpen?: boolean;
  options?: Option<T>[];
  selectedOption?: Option<T> | null;
  selectItem?: (item: Option<T>) => void;
  value?: string | number | readonly string[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SearchInput({
  value,
  isOpen,
  options,
  onChange,
  selectItem,
  selectedOption,
  ...props
}: SearchInputProps<string | number> &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement> & BaseProps,
    HTMLInputElement
  >) {
  const [isOptionsOpen, setIsOptionsOpen] = useState(isOpen || false);

  const [optionsList, setOptionsList] = useState<Option<string | number>[]>(
    options || []
  );

  const IS_EMPTY = useMemo(() => {
    return optionsList?.length === 0;
  }, [optionsList]);

  const handleFilter = useMemo(() => {
    if (!optionsList) return [];

    return optionsList.filter((item) => {
      return (
        item.key === null ||
        item.value.toLowerCase().includes(value?.toString().toLowerCase() || '')
      );
    });
  }, [optionsList, value]);

  const isSelected = useMemo(
    () => (item: Option<string | number>) => {
      return item.key === selectedOption?.key && item.key === null;
    },
    [selectedOption]
  );

  const handleSelect = useMemo(
    () => (item: Option<string | number>) => {
      setIsOptionsOpen(false);

      if (item.key !== null) {
        selectItem?.(item);
      } else {
        !!value && onChange?.({} as React.ChangeEvent<HTMLInputElement>);

        selectItem?.({ key: null, value: '' });
      }
    },
    [selectItem, onChange, value]
  );

  const SHOW_OPTIONS =
    (!!value &&
      !IS_EMPTY &&
      !props.disabled &&
      !selectedOption?.key &&
      handleFilter.length > 0) ||
    isOptionsOpen;

  useEffect(() => {
    options &&
      setOptionsList(
        options.length > 1
          ? [{ key: null, value: 'Escolha um Cooperado' }, ...(options || [])]
          : [...(options || [])]
      );
  }, [options]);

  return (
    <fieldset className="flex flex-col w-full items-center gap-2 relative">
      <div
        className={classNames(
          'flex items-center justify-start w-full border border-gray-dark500 bg-gray-dark600 shadow-none rounded-md px-4',
          {
            'bg-gray-dark700 border-gray-dark700': props.disabled,
          }
        )}
      >
        <div className="flex items-center justify-start w-full">
          <MdSearch size={25} color="#6B6B6B" />
          <BaseInput
            {...props}
            type="text"
            id={props.id}
            value={value}
            ref={props.ref}
            name={props.name}
            autoComplete="off"
            onChange={onChange}
            placeholder={IS_EMPTY ? 'Nenhuma opção disponível' : props.placeholder}
            className="appearence-none border-none focus:outline-none focus:ring-transparent w-full bg-gray-dark600 shadow-none rounded-md placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark400 text-sm"
          />
        </div>
        <MdChevronLeft
          size={20}
          color="#6B6B6B"
          aria-hidden="true"
          className="cursor-pointer"
          onClick={() => !IS_EMPTY && setIsOptionsOpen(!isOptionsOpen)}
          style={{ transform: `rotate(${isOptionsOpen ? 90 : -90}deg)` }}
        />
      </div>
      {SHOW_OPTIONS && (
        <div className="flex w-full items-start justify-start flex-col border border-gray-dark500 rounded-md bg-gray-dark600 max-h-[308px] overflow-y-auto scrollbar focus:outline-none absolute top-11 z-20">
          {handleFilter?.map((item) => (
            <span
              key={item.key}
              onClick={() => handleSelect(item)}
              className={classNames(
                'text-gray-dark500 flex w-full items-start justify-between bg-gray-dark600 shadow-none placeholder-gray-dark500 focus-within:text-gray-dark400 hover:border-primary px-4 py-3 cursor-pointer hover:text-gray-dark400 text-sm hover:bg-gray-dark550',
                {
                  'bg-gray-dark700': props.disabled,
                  'border-b border-gray-dark500 hover:border-none':
                    item.key === null,
                  'bg-gray-dark550 !text-gray-dark400': isSelected(item),
                }
              )}
            >
              {item.value}
              {isSelected(item) && <MdCheck className="text-lg font-semibold" />}
            </span>
          ))}
        </div>
      )}
    </fieldset>
  );
}
