import { Button } from 'ui';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import useToastContext from 'ui/hooks/useToast';
import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';
import ConsumerUnitFields, {
  FormFields,
  FORM_FIELDS,
} from 'consumerUnits/components/form/ConsumerUnitFields';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  ConsumerUnitUpdateUpdated,
  ConsumerUnitUpdateMutationVariables,
  CONSUMER_UNIT_UPDATE_MUTATION,
} from 'consumerUnits/graphql/consumerUnitUpdateMutation';
import { setFormError } from 'utils/form';
import {
  ConsumerUnitTypename,
  CONSUMER_UNIT_QUERY,
} from 'consumerUnits/graphql/consumerUnitQuery';
import { DEFAULT_OVERLAY_CONFIG, ToastProps } from 'ui/contexts/overlay/Toast';
import {
  ConsumerUnitDeleteMutationVariables,
  CONSUMER_UNIT_DELETE_MUTATION,
} from 'consumerUnits/graphql/consumerUnitDeleteMutation';
import { OverlayConfig, OverlayProps } from 'ui/models/overlay';
import DeleteButton from 'consumerUnits/components/DeleteButton';
import { has } from 'lodash';
import { updateCacheById } from 'graphql/apollo/cache';

const DASHBOARD_CONSUMER_UNITS_ROUTE = '/dashboard/business-entities/consumer-units';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Unidade Consumidora',
    route: DASHBOARD_CONSUMER_UNITS_ROUTE,
    current: false,
  },
  {
    name: 'Edição de Unidade Consumidora',
    route: null,
    current: true,
  },
];

const TITLE = 'Edição de Unidade Consumidora';

const UPDATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar atualizar a Unidade Consumidora',
  title: 'Algo deu errado!',
  variant: 'danger',
};
const UPDATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao atualizar a Unidade Consumidora.',
};
const FETCH_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a Unidade Consumidora',
};

export default function EditConsumerUnitsPage() {
  const { ConfirmOverlay, showConfirm, closeConfirm } = useConfirm();
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();

  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [consumerUnitUpdateMutation, { loading: updateLoading }] = useMutation<
    ConsumerUnitUpdateUpdated,
    ConsumerUnitUpdateMutationVariables
  >(CONSUMER_UNIT_UPDATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(UPDATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(UPDATE_SUCCESS_TOAST);
      push(DASHBOARD_CONSUMER_UNITS_ROUTE);
    },
  });

  const [consumerUnitDeleteMutation, { loading: deleteLoading }] =
    useMutation<ConsumerUnitDeleteMutationVariables>(CONSUMER_UNIT_DELETE_MUTATION, {
      onError() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(UPDATE_ERROR_TOAST);
      },
      onCompleted() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(UPDATE_SUCCESS_TOAST);
        push(DASHBOARD_CONSUMER_UNITS_ROUTE);
      },
    });

  const [
    consumerUnitQuery,
    { data, loading: fetchConsumerUnitLoading, error: fetchConsumerUnitError },
  ] = useLazyQuery(CONSUMER_UNIT_QUERY, {
    variables: {
      id: id,
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    resetField,
    setValue,
    control,
  } = useForm<FormFields>();

  useEffect(() => {
    if (fetchConsumerUnitError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchConsumerUnitError]);

  useEffect(() => {
    if (data?.consumerUnit) {
      FORM_FIELDS.forEach((field) => setValue(field, data.consumerUnit[field]));
      setValue('consumerUnitModality', data.consumerUnit.consumerUnitModality);
      setValue('cooperativeId', data.consumerUnit.cooperative.id);
      setValue(
        'powerDistributionUnitId',
        data.consumerUnit.powerDistributionUnit.id
      );
      setValue('cooperativeMemberId', data.consumerUnit.cooperativeMember.id);
    }
  }, [data, setValue]);

  useEffect(() => {
    consumerUnitQuery();
  }, [consumerUnitQuery, id]);

  const [confirmOverlayProps, setConfirmOverlayProps] = useState<OverlayProps>(
    DEFAULT_OVERLAY_CONFIG
  );

  const isLoading = !!(deleteLoading || updateLoading || fetchConsumerUnitLoading);

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onSubmit: SubmitHandler<FormFields> = (consumerUnitUpdateInput) => {
    consumerUnitUpdateMutation({
      variables: {
        consumerUnitUpdateInput: {
          id: Number(id),
          consumerUnitAddressCity: consumerUnitUpdateInput.consumerUnitAddressCity,
          consumerUnitAddressPostalCode: Number(
            consumerUnitUpdateInput.consumerUnitAddressPostalCode
          ),
          consumerUnitAddressState: consumerUnitUpdateInput.consumerUnitAddressState,
          consumerUnitAddressStreet:
            consumerUnitUpdateInput.consumerUnitAddressStreet,
          powerDistributionUnitCredentialsUser:
            consumerUnitUpdateInput.powerDistributionUnitCredentialsUser,
          powerDistributionUnitCredentialsPasswordInput:
            consumerUnitUpdateInput.powerDistributionUnitCredentialsPasswordInput,
          consumerUnitAddressDistrict:
            consumerUnitUpdateInput.consumerUnitAddressDistrict,
          consumerUnitAddressComplement:
            consumerUnitUpdateInput.consumerUnitAddressComplement,
        },
      },
    });
  };

  const onConfirmDelete = () =>
    consumerUnitDeleteMutation({
      variables: { id: id },
      update(cache) {
        updateCacheById(cache, Number(id), ConsumerUnitTypename);
      },
    });

  const onClickDelete = (overlayConfig: OverlayConfig) => {
    setConfirmOverlayProps({
      ...overlayConfig,
      onConfirm: onConfirmDelete,
    });

    showConfirm();
  };

  const cooperativeMembers = useMemo(
    () =>
      data?.consumerUnit
        ? [
            {
              id: data.consumerUnit.cooperativeMember.id,
              cooperativeMemberLegalName:
                data.consumerUnit.cooperativeMember.cooperativeMemberLegalName,
              cooperativeMemberDocumentId:
                data.consumerUnit.cooperativeMember.cooperativeMemberDocumentId,
              cooperativeMemberEntityType:
                data.consumerUnit.cooperativeMember.cooperativeMemberEntityType,
              cooperativeMemberEmail:
                data.consumerUnit.cooperativeMember.cooperativeMemberEmail,
              cooperative: {
                id: data.consumerUnit.cooperative.id,
                cooperativeLegalName:
                  data.consumerUnit.cooperative.cooperativeLegalName,
              },
            },
          ]
        : [],
    [data]
  );

  const powerDistributionUnits = useMemo(
    () =>
      data?.consumerUnit
        ? [
            {
              id: data.consumerUnit.powerDistributionUnit.id,
              powerDistributionUnitLegalName:
                data.consumerUnit.powerDistributionUnit
                  .powerDistributionUnitLegalName,
            },
          ]
        : [],
    [data]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm title={TITLE} breadcrumbPages={BREADCRUMB_PAGES}>
            {data?.consumerUnit && (
              <>
                {' '}
                <DeleteButton onClick={onClickDelete} disabled={isLoading} />
                <Button type="submit" size="sm" disabled={isLoading}>
                  Salvar
                </Button>
              </>
            )}
            <ConfirmOverlay {...confirmOverlayProps} onCancel={closeConfirm} />
          </DashboardMainHeaderForm>
        }
      >
        {data?.consumerUnit && (
          <ConsumerUnitFields
            errors={errors}
            register={register}
            control={control}
            powerDistributionUnits={powerDistributionUnits}
            cooperativeMembers={cooperativeMembers}
            disableFields
            setValue={setValue}
            resetField={resetField}
          />
        )}
      </Dashboard>
    </form>
  );
}
