import {
  GenerationUnit as GenerationUnitModel,
  GenerationUnitConsumptionClassTypes,
  GenerationUnitEnergySourceTypes,
  GenerationUnitConsumptionGroupTypes,
  GenerationUnitModalityType,
} from 'generationUnits/models/generationUnit';

export const GenerationUnit = (
  id = new Date().valueOf(),
  generationUnitModality = GenerationUnitModalityType.GREEN,
  generationUnitConsumptionClass = GenerationUnitConsumptionClassTypes.COMMERCIAL,
  generationUnitConsumptionGroupType = GenerationUnitConsumptionGroupTypes.A
): GenerationUnitModel => ({
  id,
  generationUnitConsumerUnitPowerDistributionUnitIdentifier: `Identifier ${id}`,
  generationUnitLegalName: `Generation unit legal name ${id}`,
  generationUnitEnergySource: GenerationUnitEnergySourceTypes.UTE,
  generationUnitAddressPostalCode: 8000000,
  generationUnitAddressCity: `City ${id}`,
  generationUnitAddressStreet: `Street ${id}`,
  generationUnitAddressComplement: null,
  generationUnitAddressDistrict: `District ${id}`,
  generationUnitAddressState: 'PR',
  powerDistributionUnitCredentialsUser: 'user',
  powerDistributionUnitCredentialsPasswordInput: 'password',
  generationUnitModality,
  generationUnitConsumptionClass,
  generationUnitConsumptionGroupType,
  generationUnitPowerCapacity: 250,
  powerDistributionUnit: {
    id,
    powerDistributionUnitLegalName: `Power distribution unit legal name ${id}`,
  },
  cooperative: {
    id,
    cooperativeLegalName: `Cooperative legal name ${id}`,
  },
  financialAccount: {
    id,
    financialAccountLegalName: `Financial account legal name ${id}`,
  },
  generationUnitCapacityFactorAverage: '1',
  generationUnitMonthlyCapacityFactor: {
    generationUnitJanCapacityFactor: '0.1',
    generationUnitFebCapacityFactor: '0.1',
    generationUnitMarCapacityFactor: '0.1',
    generationUnitAprCapacityFactor: '0.1',
    generationUnitMayCapacityFactor: '0.1',
    generationUnitJunCapacityFactor: '0.1',
    generationUnitJulCapacityFactor: '0.1',
    generationUnitAugCapacityFactor: '0.1',
    generationUnitSepCapacityFactor: '0.1',
    generationUnitOctCapacityFactor: '0.1',
    generationUnitNovCapacityFactor: '0.1',
    generationUnitDecCapacityFactor: '0.1',
  },
  cooperativeId: id,
  financialAccountId: id,
  powerDistributionUnitId: id,
});

export default GenerationUnit;
