import { gql } from '@apollo/client';

export interface ContactGroupQueryVariables {
  id: string;
}

export const contactGroupTypename = 'ContactGroup';

export const CONTACT_GROUP_QUERY = gql`
  query contactGroup($id: ID!) {
    contactGroup(id: $id) {
      id
      groups {
        id
        groupName
      }
      contacts {
        id
        contactName
        contactEmail
        contactPhone
        contactType {
          id
          contactTypeName
        }
      }
      entities {
        cooperative {
          id
        }
      }
    }
  }
`;
