import { gql } from '@apollo/client';
import { PowerDistributionUnit } from 'powerDistributionUnits/models/powerDistributionUnit';

interface PowerDistributionUnitConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: PowerDistributionUnit[];
}

export interface PowerDistributionUnitList {
  powerDistributionUnits: PowerDistributionUnitConnection;
}

export const powerDistributionUnitTypename = 'PowerDistributionUnitConnection';

export const POWER_DISTRIBUTION_UNIT_LIST_QUERY = gql`
  query powerDistributionUnits($after: String, $before: String, $pageSize: Int) {
    powerDistributionUnits(after: $after, before: $before, pageSize: $pageSize) {
      afterCursor
      beforeCursor
      entries {
        id
        powerDistributionUnitCountryState
        powerDistributionUnitDocumentId
        powerDistributionUnitLegalName
      }
    }
  }
`;
