import { gql } from '@apollo/client';
import { PowerDistributionUnit } from 'powerDistributionUnits/models/powerDistributionUnit';

export interface PowerDistributionUnitUpdateMutationInput {
  id: number;
  powerDistributionUnitLegalName: string;
  powerDistributionUnitDocumentId: string;
  powerDistributionUnitCountryState: string;
}

export interface PowerDistributionUnitMutationVariables {
  powerDistributionUnitUpdateInput: PowerDistributionUnitUpdateMutationInput;
}

export interface PowerDistributionUnitUpdated {
  powerDistributionUnitUpdate: PowerDistributionUnit;
}

export const POWER_DISTRIBUTION_UNIT_UPDATE_MUTATION = gql`
  mutation powerDistributionUnitUpdate(
    $powerDistributionUnitUpdateInput: PowerDistributionUnitUpdateInput!
  ) {
    powerDistributionUnitUpdate(input: $powerDistributionUnitUpdateInput) {
      id
      powerDistributionUnitCountryState
      powerDistributionUnitDocumentId
      powerDistributionUnitLegalName
    }
  }
`;
