import { gql } from '@apollo/client';

import { GenerationUnitLeaseCycle } from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

interface GenerationUnitLeaseCycleConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: GenerationUnitLeaseCycle[];
}

export interface GenerationUnitLeaseCycleList {
  generationUnitLeaseCycles: GenerationUnitLeaseCycleConnection;
}

export const generationUnitLeaseCyclesTypename =
  'GenerationUnitLeaseCycleConnection';

const MAX_PAGE_SIZE = 100;

export const GENERATION_UNIT_LEASE_CYCLES_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const GENERATION_UNIT_LEASE_CYCLES_QUERY = gql`
  query generationUnitLeaseCycles(
    $after: String
    $before: String
    $pageSize: Int
    $filters: GenerationUnitLeaseCycleFilterOptions
  ) {
    generationUnitLeaseCycles(
      after: $after
      before: $before
      pageSize: $pageSize
      filters: $filters
    ) {
      afterCursor
      beforeCursor
      entries {
        id
        generationUnitLeaseCycleDate
        generationUnitLeaseCycleStatus
        apportionmentCycleConsumerUnitCount
        powerDistributionUnitBillDatas {
          id
          billConsumerUnitId
          powerDistributionUnitId
          billDueDate
          billIssueDate
          billReferenceDate
          billNextReadingDate
          billReadingStartedAt
          billReadingFinishedAt
          billTotalValue
          billTotalReadingDays
          billConsumedEnergyKwh {
            consumedEnergyKwhTotal
            consumedEnergyKwhOnPeak
            consumedEnergyKwhOffPeak
          }
          billExcessEnergyCredits {
            excessEnergyCreditsTotal
            excessEnergyCreditsOnPeak
            excessEnergyCreditsOffPeak
          }
          billConsumedEnergyCredits {
            consumedEnergyCreditsTotal
            consumedEnergyCreditsOnPeak
            consumedEnergyCreditsOffPeak
          }
          billGeneratedEnergyCredits {
            generatedEnergyCreditsTotal
            generatedEnergyCreditsOnPeak
            generatedEnergyCreditsOffPeak
          }
          billConsumedEnergyCreditsTariffs {
            consumedEnergyCreditsTariffsTotal
            consumedEnergyCreditsTariffsOnPeak
            consumedEnergyCreditsTariffsOffPeak
          }
          billConsumerUnitType
          billConsumerUnitConsumptionGroupType
        }
        consumerUnitBillingCapture {
          id
          consumerUnitId
          consumerUnitBillingCaptureUrl
          consumerUnitBillingCaptureError
          consumerUnitBillingCaptureStatus
          consumerUnitBillingCaptureReferenceMonth
          consumerUnitPowerDistributionUnitIdentifier
        }
        generationUnit {
          id
          generationUnitConsumerUnitPowerDistributionUnitIdentifier
          generationUnitLegalName
          generationUnitCapacityFactorAverage
          generationUnitEnergySource
          generationUnitPowerCapacity
          generationUnitAddressCity
          generationUnitAddressPostalCode
          generationUnitAddressState
          generationUnitAddressStreet
          generationUnitAddressDistrict
          generationUnitAddressComplement
          powerDistributionUnitCredentialsUser
          generationUnitModality
          generationUnitConsumptionClass
          generationUnitConsumptionGroupType
          generationUnitMonthlyCapacityFactor {
            generationUnitJanCapacityFactor
            generationUnitFebCapacityFactor
            generationUnitMarCapacityFactor
            generationUnitAprCapacityFactor
            generationUnitMayCapacityFactor
            generationUnitJunCapacityFactor
            generationUnitJulCapacityFactor
            generationUnitAugCapacityFactor
            generationUnitSepCapacityFactor
            generationUnitOctCapacityFactor
            generationUnitNovCapacityFactor
            generationUnitDecCapacityFactor
          }
          powerDistributionUnit {
            id
            powerDistributionUnitCountryState
            powerDistributionUnitDocumentId
            powerDistributionUnitLegalName
            powerDistributionUnitDeletedAt
          }
        }
      }
    }
  }
`;
