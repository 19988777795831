import { useEffect, useState } from 'react';
import { has } from 'lodash';
import { setFormError } from 'utils/form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { updateCacheById } from 'graphql/apollo/cache';
import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { OverlayConfig, OverlayProps } from 'ui/models/overlay';
import { DEFAULT_OVERLAY_CONFIG, ToastProps } from 'ui/contexts/overlay/Toast';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import DeleteButton from 'financialAccounts/components/DeleteButton';
import FinancialAccountFields, {
  FormFields,
  FORM_FIELDS,
} from 'financialAccounts/components/form/FinancialAccountsFormFields';
import {
  FinancialAccountUpdated,
  FinancialAccountUpdateMutationVariables,
  FINANCIAL_ACCOUNT_UPDATE_MUTATION,
} from 'financialAccounts/graphql/financialAccountUpdateMutation';
import {
  financialAccountTypename,
  FINANCIAL_ACCOUNT_QUERY,
} from 'financialAccounts/graphql/financialAccountQuery';
import {
  FinancialAccountDeleteMutationVariables,
  FINANCIAL_ACCOUNT_DELETE_MUTATION,
} from 'financialAccounts/graphql/financialAccountDeleteMutation';
import {
  BankList,
  BANKS_QUERY,
  BANKS_SELECT_QUERY_VARIABLES,
} from 'banks/graphql/banksQuery';
import { unMaskFormFields } from 'utils/unmask';

const DASHBOARD_FINANCIAL_ACCOUNTS_ROUTE =
  '/dashboard/billing-cycle/financial-accounts';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Conta Financeira',
    route: DASHBOARD_FINANCIAL_ACCOUNTS_ROUTE,
    current: false,
  },
  {
    name: 'Edição de Conta Financeira',
    route: null,
    current: true,
  },
];

const TITLE = 'Edição de Conta Financeira';

const UPDATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar atualizar a Conta Financeira',
  title: 'Algo deu errado!',
  variant: 'danger',
};
const UPDATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao atualizar a Conta Financeira.',
};
const FETCH_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a Conta Financeira',
};

export default function EditFinancialAccountsPage() {
  const { ConfirmOverlay, showConfirm, closeConfirm } = useConfirm();
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();

  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [
    banksSelect,
    { data: dataBanks, loading: loadingBanks, refetch: refetchBanks },
  ] = useLazyQuery<BankList>(BANKS_QUERY, BANKS_SELECT_QUERY_VARIABLES);

  useEffect(() => {
    refetchBanks && refetchBanks();

    banksSelect();
  }, [banksSelect, refetchBanks]);

  const [financialAccountUpdateMutation, { loading: updateLoading }] = useMutation<
    FinancialAccountUpdated,
    FinancialAccountUpdateMutationVariables
  >(FINANCIAL_ACCOUNT_UPDATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'message')) {
        addToast(UPDATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(UPDATE_SUCCESS_TOAST);
      push(DASHBOARD_FINANCIAL_ACCOUNTS_ROUTE);
    },
  });

  const [financialAccountDeleteMutation, { loading: deleteLoading }] =
    useMutation<FinancialAccountDeleteMutationVariables>(
      FINANCIAL_ACCOUNT_DELETE_MUTATION,
      {
        onError() {
          closeConfirm();
          setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
          addToast(UPDATE_ERROR_TOAST);
        },
        onCompleted() {
          closeConfirm();
          setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
          addToast(UPDATE_SUCCESS_TOAST);
          push(DASHBOARD_FINANCIAL_ACCOUNTS_ROUTE);
        },
      }
    );

  const [
    financialAccountQuery,
    {
      data,
      loading: fetchFinancialAcccountLoading,
      error: fetchFinancialAccountError,
    },
  ] = useLazyQuery(FINANCIAL_ACCOUNT_QUERY, {
    variables: {
      id: id,
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
    control,
  } = useForm<FormFields>();

  useEffect(() => {
    if (fetchFinancialAccountError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchFinancialAccountError]);

  useEffect(() => {
    if (data?.financialAccount) {
      FORM_FIELDS.forEach((field) => setValue(field, data.financialAccount[field]));
      setValue('bankId', data?.financialAccount?.bank?.id);
    }
  }, [data, setValue]);

  useEffect(() => {
    financialAccountQuery();
  }, [financialAccountQuery, id]);

  const [confirmOverlayProps, setConfirmOverlayProps] = useState<OverlayProps>(
    DEFAULT_OVERLAY_CONFIG
  );

  const isLoading = !!(
    loadingBanks ||
    deleteLoading ||
    updateLoading ||
    fetchFinancialAcccountLoading
  );

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onSubmit: SubmitHandler<FormFields> = (financialAccountUpdateInput) => {
    financialAccountUpdateInput = unMaskFormFields(financialAccountUpdateInput);

    financialAccountUpdateMutation({
      variables: {
        financialAccountUpdateInput: {
          ...financialAccountUpdateInput,
          id: Number(id),
          bankId: Number(financialAccountUpdateInput.bankId),
          financialAccountAddressPostalCode: Number(
            financialAccountUpdateInput.financialAccountAddressPostalCode
          ),
        },
      },
    });
  };

  const onConfirmDelete = () =>
    financialAccountDeleteMutation({
      variables: { id: id },
      update(cache) {
        updateCacheById(cache, Number(id), financialAccountTypename);
      },
    });

  const onClickDelete = (overlayConfig: OverlayConfig) => {
    setConfirmOverlayProps({
      ...overlayConfig,
      onConfirm: onConfirmDelete,
    });

    showConfirm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm title={TITLE} breadcrumbPages={BREADCRUMB_PAGES}>
            {data?.financialAccount && (
              <>
                {' '}
                <DeleteButton onClick={onClickDelete} disabled={isLoading} />
                <Button type="submit" size="sm" disabled={isLoading}>
                  Salvar
                </Button>
              </>
            )}
            <ConfirmOverlay {...confirmOverlayProps} onCancel={closeConfirm} />
          </DashboardMainHeaderForm>
        }
      >
        {data?.financialAccount && (
          <FinancialAccountFields
            errors={errors}
            control={control}
            register={register}
            financialAccountEntityTypeDisabled
            banks={dataBanks?.banks.entries || []}
          />
        )}
      </Dashboard>
    </form>
  );
}
