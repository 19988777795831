import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { ReactNode, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { Link } from 'react-router-dom';
import type { SubMenuSidebar } from 'dashboard/models/navigation';

type Props = {
  to: string | undefined;
  icon: ReactNode;
  isCurrentPage: boolean;
  name: string;
  subtitle: string;
  submenu?: SubMenuSidebar[];
  disabled: boolean;
};

export default function NavigationItem({
  to,
  icon,
  isCurrentPage,
  name,
  subtitle,
  submenu,
  disabled,
}: Props) {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'right',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 8 } },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  if (disabled) {
    popperElement?.setAttribute('hidden', '');
  }

  const menuItemClasses = (
    disable: boolean,
    open: boolean
  ): [string, Record<string, boolean>] => {
    const firstReturn = `group w-full p-3 rounded-md flex flex-col text-center text-xs font-medium items-center ${
      disable
        ? 'cursor-default text-gray-dark550 '
        : 'hover:bg-gray-dark800 text-gray-dark500 hover:text-gray-dark400'
    }`;

    const secondReturn = {
      'bg-gray-dark800': open && !disable,
    };

    return [firstReturn, secondReturn];
  };

  const subMenuItemClasses = (disable: boolean, active: boolean): string[] => {
    const fixClass = 'flex items-center w-full px-4 py-2 text-sm font-semibold';
    const toggleEnableClass = disable
      ? 'text-gray-dark600 leading-5 text-left'
      : 'leading-5 text-left hover:text-gray-dark400 hover:bg-gray-dark700';

    const toggleActiveClass =
      active && !disable ? 'text-gray-dark6b' : 'text-gray-darkb6';

    return [`${fixClass} ${toggleEnableClass}`, toggleActiveClass];
  };

  return (
    <Menu>
      {({ open }) => (
        <>
          <div ref={setTargetElement} className="rounded-md shadow-sm">
            {submenu ? (
              <Menu.Button
                aria-current={isCurrentPage ? 'page' : undefined}
                className={classNames(...menuItemClasses(disabled, open))}
              >
                {icon}
                <span className="mt-2">{name}</span>
              </Menu.Button>
            ) : (
              <Link
                to={to ?? ''}
                onClick={(e) => disabled && e.preventDefault()}
                aria-current={isCurrentPage ? 'page' : undefined}
                className={classNames(...menuItemClasses(disabled, open))}
              >
                {icon}
                <span className="mt-2">{name}</span>
              </Link>
            )}
          </div>

          {submenu && (
            <Portal>
              <div ref={popperElRef} style={styles.popper} {...attributes.popper}>
                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  beforeEnter={() => setPopperElement(popperElRef.current)}
                  afterLeave={() => setPopperElement(null)}
                >
                  <Menu.Items
                    static
                    className="w-56 origin-top-right text-gray-dark500 bg-gray-dark800 border border-gray-dark800 rounded-md shadow-lg outline-none"
                  >
                    <div className="px-4 py-3">
                      <p className="text-xs font-semibold">{subtitle}</p>
                    </div>

                    <div className="py-1">
                      {submenu.map((item) => {
                        return (
                          <Menu.Item disabled={item.disabled} key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.to}
                                className={classNames(
                                  ...subMenuItemClasses(item.disabled, active)
                                )}
                              >
                                <item.icon
                                  className="h-4 w-4 mr-3"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </Menu.Items>

                  <div
                    ref={setArrowElement}
                    style={styles.arrow}
                    className="-left-1"
                  >
                    <div className="w-2 h-2 bg-gray-dark800 rotate-45" />
                  </div>
                </Transition>
              </div>
            </Portal>
          )}
        </>
      )}
    </Menu>
  );
}
