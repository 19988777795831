import { Button } from 'ui';
import { MdEdit } from 'react-icons/md';
import classNames from 'classnames';

type Props = {
  disabled?: boolean;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
};

export default function EditButton({ onClick, disabled }: Props) {
  return (
    <Button
      data-testid="edit-button"
      variant="icon"
      size="none"
      onClick={onClick}
      className={classNames('', {
        'text-gray-dark600 hover:text-gray-dark600': disabled,
      })}
    >
      <MdEdit className="w-5 h-5" aria-hidden="true" />
    </Button>
  );
}
