import { gql } from '@apollo/client';
import { Cooperative } from 'cooperatives/models/cooperative';

interface CooperativeConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: Cooperative[];
}

export interface CooperativesList {
  cooperatives: CooperativeConnection;
}

export const cooperativesTypename = 'CooperativeConnection';

export const COOPERATIVES_QUERY = gql`
  query cooperatives($after: String, $before: String, $pageSize: Int) {
    cooperatives(after: $after, before: $before, pageSize: $pageSize) {
      afterCursor
      beforeCursor
      entries {
        id
        cooperativeLegalName
        cooperativeAddressState
        cooperativeAddressDistrict
        cooperativeSubscriptionCostCents
        cooperativeDocumentId
        cooperativeDeletedAt
        cooperativeHeadquarter {
          cooperativeLegalName
        }
        financialAccount {
          financialAccountLegalName
        }
      }
    }
  }
`;
