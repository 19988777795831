import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import CooperativeMembersTable from 'cooperatives/components/table/CooperativeMembersTable';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import {
  COOPERATIVE_MEMBERS_QUERY,
  CooperativeMembersList,
} from 'cooperatives/graphql/cooperativeMembersQuery';
import {
  COOPERATIVE_MEMBER_DELETE_MUTATION,
  CooperativeMemberDeleteMutationVariables,
  CooperativeMemberTypename,
} from 'cooperatives/graphql/cooperativeMemberDeleteMutation';
import { CooperativeMember } from 'cooperatives/models/cooperativeMember';
import CooperativeMemberFormFilters, {
  FormFields,
} from 'cooperatives/components/form/CooperativeMemberFormFilters';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { useHistory } from 'react-router-dom';
import { updateCacheById } from 'graphql/apollo/cache';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import AddButton from 'dashboard/components/dashboard/AddButton';
import { SubmitHandler, useForm } from 'react-hook-form';

type ListState = {
  cooperativeMembers: CooperativeMember[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const DASHBOARD_COOPERATIVE_MEMBERS_CREATE_ROUTE =
  '/dashboard/business-entities/cooperative-members/create';

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de Membros da cooperativa',
};

const DELETE_SUCCESS_TOAST: ToastProps = {
  text: 'Membro da cooperativa deletado com sucesso',
  title: 'Sucesso',
  variant: 'primary',
};

const DETETE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar deletar Membro da cooperativa',
  title: 'Algo deu errado!',
  variant: 'danger',
};

export default function ListCooperativeMembersPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>();

  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const { ConfirmOverlay, closeConfirm, showConfirm } = useConfirm();
  const [currentCooperativeMemberId, setCurrentCooperativeMemberId] =
    useState<number>(0);
  const [listState, setListState] = useState<ListState>({
    cooperativeMembers: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [cooperativeMembersList, { data, loading, error, refetch }] =
    useLazyQuery<CooperativeMembersList>(
      COOPERATIVE_MEMBERS_QUERY,
      INITIAL_QUERY_STATE_CONFIG
    );

  const [
    cooperativeMemberDeleteMutation,
    { loading: cooperativeMemberDeleteLoading },
  ] = useMutation<CooperativeMemberDeleteMutationVariables>(
    COOPERATIVE_MEMBER_DELETE_MUTATION,
    {
      onError() {
        addToast(DETETE_ERROR_TOAST);
      },
      onCompleted() {
        addToast(DELETE_SUCCESS_TOAST);
      },
    }
  );

  useEffect(() => {
    cooperativeMembersList();
  }, [cooperativeMembersList]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading = loading || cooperativeMemberDeleteLoading;

  const onSubmit: SubmitHandler<FormFields> = (cooperativeMemberCreateInput: {
    [key: string]: any;
  }) => {
    Object.keys(cooperativeMemberCreateInput).forEach((key) => {
      if (!!!cooperativeMemberCreateInput[key]) {
        delete cooperativeMemberCreateInput[key];
      }
    });

    refetch({
      filters: cooperativeMemberCreateInput,
    });
  };

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    if (data) {
      setListState({
        cooperativeMembers: data.cooperativeMembers.entries,
        hasNextPage: !!data.cooperativeMembers.afterCursor,
        hasPreviousPage: !!data.cooperativeMembers.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  const handleClickNext = () => {
    refetch &&
      refetch({
        after: data?.cooperativeMembers.afterCursor,
        before: null,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data?.cooperativeMembers.beforeCursor,
      });
  };

  const onClickEditButton = (id: number) =>
    push(`/dashboard/business-entities/cooperative-members/${id}/edit`);

  const onClickRemoveButton = (id: number) => {
    setCurrentCooperativeMemberId(id);
    showConfirm();
  };

  const onConfirmDelete = () => {
    closeConfirm();

    cooperativeMemberDeleteMutation({
      variables: { id: currentCooperativeMemberId },
      update(cache) {
        updateCacheById(
          cache,
          currentCooperativeMemberId,
          CooperativeMemberTypename
        );
      },
    });
  };

  const onCancelDelete = () => closeConfirm();

  const onClickAddButton = () => push(DASHBOARD_COOPERATIVE_MEMBERS_CREATE_ROUTE);

  return (
    <Dashboard
      dashboardMainHeaderTitle={
        <DashboardMainHeaderForm title="Gestão de Cooperados">
          <AddButton onClick={onClickAddButton} label="Cooperado" />
        </DashboardMainHeaderForm>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CooperativeMemberFormFilters
          errors={errors}
          register={register}
          isLoading={isLoading}
        />
      </form>

      <div className="rounded-lg bg-gray-background">
        {listState && (
          <>
            <CooperativeMembersTable
              cooperativeMembers={listState.cooperativeMembers}
              onClickEditButton={onClickEditButton}
              onClickRemoveButton={onClickRemoveButton}
              disableEditButton={isLoading}
              disableRemoveButton={isLoading}
            />
            <Pagination
              onNextClick={handleClickNext}
              onPreviousClick={handleClickBefore}
              disableNext={!listState.hasNextPage || isLoading}
              disableBefore={!listState.hasPreviousPage || isLoading}
            />
          </>
        )}
        <ConfirmOverlay
          title="Desativação do cooperado!"
          text="Tem certeza que deseja desativar esse cooperado? Todos os dados serão permanentemente removidos do nosso sistema. Essa ação não poderá ser revertida."
          variant="danger"
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
        />
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
