import * as React from 'react';
import { PowerDistributionUnit } from 'powerDistributionUnits/models/powerDistributionUnit';
import { TableBodyColumn } from 'ui/components/table';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';
import TableBodyRow from 'ui/components/table/TableBodyRow';

type Props = {
  powerDistributionUnit: PowerDistributionUnit;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function PowerDistributionUnitsTableItem({
  powerDistributionUnit: {
    id,
    powerDistributionUnitLegalName,
    powerDistributionUnitDocumentId,
    powerDistributionUnitCountryState,
  },
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  const handleClickEditButton = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  return (
    <TableBodyRow dataTestId={`power-distribution-units-row-id-${id}`}>
      <TableBodyColumn className="font-bold">
        {powerDistributionUnitLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="font-bold">
        {powerDistributionUnitDocumentId}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {powerDistributionUnitCountryState}
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} />
        <RemoveButton onClick={handleClickRemoveButton} />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
