import { useEffect, useState } from 'react';
import { has, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { OverlayProps } from 'ui/models/overlay';
import { DEFAULT_OVERLAY_CONFIG, ToastProps } from 'ui/contexts/overlay/Toast';
import { formatDateToSubmitDateString, setFormError } from 'utils/form';
import { unMaskFormFields } from 'utils/unmask';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import CommercialAgreementsFormFields, {
  FormFields,
  FORM_FIELDS,
} from 'commercialAgreements/components/form/CommercialAgreementsFormFields';
import {
  CommercialAgreementTypename,
  COMMERCIAL_AGREEMENT_QUERY,
} from 'commercialAgreements/graphql/commercialAgreementQuery';
import {
  CommercialAgreementUpdated,
  CommercialAgreementUpdateMutationVariables,
  COMMERCIAL_AGREEMENT_UPDATE_MUTATION,
} from 'commercialAgreements/graphql/commercialAgreementUpdateMutation';
import {
  GENERATION_UNITS_SELECT_QUERY,
  GENERATION_UNITS_SELECT_QUERY_VARIABLES,
  GenerationUnitsSelectList,
} from 'generationUnits/graphql/generationUnitsSelectQuery';
import { updateCacheById } from 'graphql/apollo/cache';

const DASHBOARD_COMMERCIAL_AGREEMENTS_ROUTE =
  '/dashboard/billing-cycle/commercial-agreements';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Acordos Comerciais',
    route: DASHBOARD_COMMERCIAL_AGREEMENTS_ROUTE,
    current: false,
  },
  {
    name: 'Editar Acordo Comercial',
    route: null,
    current: true,
  },
];

const TITLE = 'Editar Acordo Comercial';

const UPDATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar atualizar o acordo',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const UPDATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao atualizar o acordo.',
};

const FETCH_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar o acordo',
};

export default function EditCommercialAgreementsPage() {
  const { ConfirmOverlay, closeConfirm } = useConfirm();
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [commercialAgreementUpdateMutation, { loading: updateLoading }] =
    useMutation<
      CommercialAgreementUpdated,
      CommercialAgreementUpdateMutationVariables
    >(COMMERCIAL_AGREEMENT_UPDATE_MUTATION, {
      onError(error: ApolloError) {
        if (has(error.graphQLErrors[0], 'details')) {
          addToast(UPDATE_ERROR_TOAST);
        }
        setFormError(
          error,
          (field: string, error: ErrorOption) => {
            setError(field as keyof FormFields, error);
            setTimeout(() => clearErrors(), 2500);
          },
          t
        );
      },
      onCompleted() {
        addToast(UPDATE_SUCCESS_TOAST);
        push(DASHBOARD_COMMERCIAL_AGREEMENTS_ROUTE);
      },
    });

  const [
    commercialAgreementQuery,
    {
      data,
      loading: fetchCommercialAgreementLoading,
      error: fetchCommercialAgreementError,
    },
  ] = useLazyQuery(COMMERCIAL_AGREEMENT_QUERY, {
    variables: {
      id: id,
    },
  });

  const [
    generationUnitsSelect,
    {
      data: dataGenerationUnits,
      loading: loadingGenerationUnits,
      refetch: refetchGenerationUnits,
    },
  ] = useLazyQuery<GenerationUnitsSelectList>(
    GENERATION_UNITS_SELECT_QUERY,
    GENERATION_UNITS_SELECT_QUERY_VARIABLES
  );

  const {
    register,
    control,
    handleSubmit,
    setError,
    clearErrors,
    resetField,
    formState: { errors },
    setValue,
  } = useForm<FormFields>();
  useEffect(() => {
    if (fetchCommercialAgreementError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchCommercialAgreementError]);
  useEffect(() => {
    if (data?.commercialAgreement) {
      const { commercialAgreementStartValidity } = data.commercialAgreement;
      const commercialAgreementFields = Object.assign(
        omit(
          data?.commercialAgreement,
          'commercialAgreementStartValidity'
        ) as FormFields,
        {
          commercialAgreementStartValidity: new Date(
            commercialAgreementStartValidity + 'T00:00:00'
          ),
        }
      );
      FORM_FIELDS.forEach((field) => {
        return setValue(field, commercialAgreementFields[field]);
      });
      setValue('generationUnitId', data.commercialAgreement.generationUnit.id);
    }
  }, [data, setValue]);

  useEffect(() => {
    commercialAgreementQuery();
  }, [commercialAgreementQuery, id]);

  useEffect(() => {
    refetchGenerationUnits && refetchGenerationUnits();
    generationUnitsSelect();
  }, [generationUnitsSelect, refetchGenerationUnits]);

  const [confirmOverlayProps] = useState<OverlayProps>(DEFAULT_OVERLAY_CONFIG);

  const isLoading = !!(
    updateLoading ||
    fetchCommercialAgreementLoading ||
    loadingGenerationUnits
  );

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }
    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onSubmit: SubmitHandler<FormFields> = (commercialAgreementUpdateInput) => {
    const commercialAgreementStartValidity =
      commercialAgreementUpdateInput.commercialAgreementStartValidity;
    const convertCommercialAgreementStartValidity = new Date(
      commercialAgreementStartValidity.getFullYear(),
      commercialAgreementStartValidity.getMonth(),
      1
    );
    commercialAgreementUpdateInput = unMaskFormFields(
      commercialAgreementUpdateInput
    );
    commercialAgreementUpdateMutation({
      variables: {
        commercialAgreementUpdateInput: {
          id: Number(id),
          commercialAgreementHasPisCofins:
            commercialAgreementUpdateInput.commercialAgreementHasPisCofins,
          commercialAgreementHasExtraRemuneration:
            commercialAgreementUpdateInput.commercialAgreementHasExtraRemuneration,
          commercialAgreementReceiptRule:
            commercialAgreementUpdateInput.commercialAgreementReceiptRule,
          commercialAgreementOemValue:
            commercialAgreementUpdateInput.commercialAgreementOemValue,
          commercialAgreementLeaseValue:
            commercialAgreementUpdateInput.commercialAgreementLeaseValue,
          commercialAgreementHasOem:
            commercialAgreementUpdateInput.commercialAgreementHasOem,
          commercialAgreementDiscountMax:
            commercialAgreementUpdateInput.commercialAgreementDiscountMax,
          commercialAgreementDiscountMin:
            commercialAgreementUpdateInput.commercialAgreementDiscountMin,
          commercialAgreementBaseCostPerCreditIcms:
            commercialAgreementUpdateInput.commercialAgreementBaseCostPerCreditIcms,
          commercialAgreementBaseCostPerCredit:
            commercialAgreementUpdateInput.commercialAgreementBaseCostPerCredit,
          commercialAgreementStartValidity: formatDateToSubmitDateString(
            String(convertCommercialAgreementStartValidity)
          ),
        },
      },
      update(cache) {
        updateCacheById(cache, Number(id), CommercialAgreementTypename);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm title={TITLE} breadcrumbPages={BREADCRUMB_PAGES}>
            {data?.commercialAgreement && (
              <>
                <Button type="submit" size="sm" disabled={isLoading}>
                  Salvar
                </Button>
              </>
            )}
            <ConfirmOverlay {...confirmOverlayProps} onCancel={closeConfirm} />
          </DashboardMainHeaderForm>
        }
      >
        {data?.commercialAgreement && (
          <CommercialAgreementsFormFields
            disableFields={true}
            generationUnits={dataGenerationUnits?.generationUnits.entries}
            setValue={setValue}
            resetField={resetField}
            clearErrors={clearErrors}
            control={control}
            register={register}
            errors={errors}
          />
        )}
      </Dashboard>
    </form>
  );
}
