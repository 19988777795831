import Table from 'ui/components/table/Table';
import TableBody from 'ui/components/table/TableBody';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';

import { Charges } from 'charges/models/charges';

import ChargesTableItem from './ChargesTableItem';

type Props = {
  charges: Charges[];
};

export default function ChargesTable({ charges }: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>MÊS</TableHeadColumn>
        <TableHeadColumn>COOPERADO</TableHeadColumn>
        <TableHeadColumn>UC</TableHeadColumn>
        <TableHeadColumn>VALOR</TableHeadColumn>
        <TableHeadColumn>MULTA/JUROS</TableHeadColumn>
        <TableHeadColumn>CRIAÇÃO</TableHeadColumn>
        <TableHeadColumn>VENCIMENTO</TableHeadColumn>
        <TableHeadColumn>PAGAMENTO</TableHeadColumn>
        <TableHeadColumn>STATUS</TableHeadColumn>
        <TableHeadColumn>ECONOMIA</TableHeadColumn>
        <TableHeadColumn className="text-right">FATURA</TableHeadColumn>
      </TableHead>
      <TableBody>
        {charges.map((charge: Charges) => (
          <ChargesTableItem key={charge.id} charges={charge} />
        ))}
      </TableBody>
    </Table>
  );
}
