import { gql } from '@apollo/client';
import { ApportionmentCycleConsumerUnit } from 'apportionmentCycles/models/apportionmentCycleConsumerUnit';

interface ApportionmentCycleConsumerUnitConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: ApportionmentCycleConsumerUnit[];
}

export interface ApportionmentCycleConsumerUnitsList {
  apportionmentCycleConsumerUnits: ApportionmentCycleConsumerUnitConnection;
}

export const ApportionmentCycleConsumerUnitsTypename =
  'ApportionmentCycleConsumerUnitConnection';

export const APPORTIONMENT_CYCLE_CONSUMER_UNITS_QUERY = gql`
  query apportionmentCycleConsumerUnits(
    $after: String
    $before: String
    $pageSize: Int
    $filters: ApportionmentCycleConsumerUnitFilterOptions
  ) {
    apportionmentCycleConsumerUnits(
      after: $after
      before: $before
      pageSize: $pageSize
      filters: $filters
    ) {
      entries {
        id
        apportionmentCycleConsumerUnitPercentual
        apportionmentCycleConsumerUnitReferenceMonth
        consumerUnit {
          id
          consumerUnitPowerDistributionUnitIdentifier
          cooperativeMember {
            id
            cooperativeMemberLegalName
          }
        }
        generationUnit {
          id
          generationUnitLegalName
        }
        leaseCycleCredit {
          id
          leaseCycleCreditsOnPeak
          leaseCycleCreditsOffPeak
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
