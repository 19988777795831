import { gql } from '@apollo/client';
import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';

export interface ConsumerUnitQueryVariables {
  id: string;
}

export interface ConsumerUnitUpdated {
  consumerUnit: ConsumerUnit;
}

export const ConsumerUnitTypename = 'ConsumerUnit';

export const CONSUMER_UNIT_QUERY = gql`
  query consumerUnit($id: ID!) {
    consumerUnit(id: $id) {
      id
      consumerUnitPowerDistributionUnitIdentifier
      consumerUnitAddressCity
      consumerUnitAddressPostalCode
      consumerUnitAddressState
      consumerUnitAddressStreet
      consumerUnitAddressDistrict
      consumerUnitAddressComplement
      powerDistributionUnitCredentialsUser
      consumerUnitModality
      consumerUnitConsumptionClass
      consumerUnitConsumptionGroupType
      powerDistributionUnitCredentialsUser
      cooperative {
        id
        cooperativeLegalName
      }
      cooperativeMember {
        id
        cooperativeMemberLegalName
        cooperativeMemberDocumentId
      }
      powerDistributionUnit {
        id
        powerDistributionUnitLegalName
      }
    }
  }
`;
